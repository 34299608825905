import React, {useState, useContext, useEffect} from 'react';
// import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import { Box, Container } from '@mui/system';
import { BarChart, Bar, Cell, XAxis, Tooltip, PieChart, Pie, Label } from 'recharts';
import { Swiper, SwiperSlide } from "swiper/react";
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import Loading from "../Layouts/loading";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import moment from 'moment';
import '../styles/ownedProperty.css';


// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

// const Img = styled('img')({
//     margin: 'auto',
//     display: 'block',
//     maxWidth: '100%',
//     maxHeight: '100%',
// });

const data_circle1 = [
  { name: "稼働率", value: 10 }, //10戸のうち10戸
  { name: "非稼働率", value: 0 },
];
const data_circle2 = [
  { name: "稼働率", value: 17 }, //20戸のうち17戸
  { name: "非稼働率", value: 3 },
];
const data_circle3 = [
  { name: "稼働率", value: 9 }, //10戸のうち9戸
  { name: "非稼働率", value: 1 },
];
//円グラフの各領域の色を定義
const COLORS = [
  '#003366',
  '#83A3B8',
];

// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
//   const RADIAN = Math.PI / 180;
//   const radius = innerRadius * 0.01;
//   // const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);
//   return (
//     <text
//       x={x} //ラベルを表示させる場所を指定
//       y={y} //ラベルを表示させる場所を指定
//       fill="black" //ラベルの色を指定
//       textAnchor={x > cx ? 'start' : 'end'}
//       dominantBaseline="central"
//     >
//       {/* ラベルで表示するテキストを指定 */}
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

const data_bar = [
    {
      month: '1', 'sokin': 112300,
    },
    {
      month: '2', 'sokin': 113500,
    },
    {
      month: '3', 'sokin': 115300,
    },
    {
      month: '4', 'sokin': 102300,
    },
    {
      month: '5', 'sokin': 102500,
    },
    {
      month: '6', 'sokin': 102600,
    },
    {
      month: '7', 'sokin': 102100,
    },
    {
      month: '8', 'sokin': 102200,
    },
    {
      month: '9', 'sokin': 102300,
    },
    {
      month: '10', 'sokin': 103500,
    },
    {
      month: '11', 'sokin': 105600,
    },
    {
      month: '12', 'sokin': 112300,
    },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box sx={{backgroundColor: 'white', border: '1px solid'}}>
          <Box component='p'>{`${label}月`}</Box>
          <Box component='p'>{`収支：${payload[0].value.toLocaleString()}円`}</Box>
          {/* <p className="desc">Anything you want can be displayed here.</p> */}
        </Box>
      );
    }
  
    return null;
  };

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

export default function OwnedPropertyDetail() {
  const [focusBar1, setFocusBar1] = useState(null);
  const [focusBarIndex1, setFocusBarIndex1] = useState(null);
  const [focusBar2, setFocusBar2] = useState(null);
  const [focusBar3, setFocusBar3] = useState(null);

  const { instance, inProgress, accounts } = useMsal();
  const [data_loading, setDataLoading] = useState(0);//

  const [owned_property_sousu, setOwnedPropertySousu] = useState([]);//物件総数
  const [owned_property_list, setOwnedPropertyList] = useState([]);//物件リスト
  const [owned_property_list_disp, setOwnedPropertyListDisp] = useState([]);//物件リスト表示用
  const [owned_property_page_count, setOwnedPropertyPageCount] = useState([]);//ページ数

  // 物件リスト
  const getOwnedPropertyListData = async () => {

      var token = await GetAccessToken({ instance, inProgress, accounts }); 

      const f = await fetch(url + `/v2/t/owner/room/syousai`, { method: 'post', body: JSON.stringify({year:moment().format('YYYY')}), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
      const d = await f.json()
      setOwnedPropertyList(d.list)
      setOwnedPropertySousu(d.sousu)

      const page = d.list.length == 0 ? 0 : Math.ceil(d.list.length/3)

      let list = []
      for(let i=0; i<page; i++){
        let p = [...Array(3)]
        for(let j=0; j<3; j++){
          if(d.list.length >= (i*3) + (j+1) - 1){
            //あるとき
            list = list.concat(d.list[(i*3) + (j+1) -1])
          }else{
            //ないときは空を入れる。
            list = list.concat([])
          }
        }
        
      }
      // console.log("list")
      // console.log("bbbbb")
      // console.log(list)

      setOwnedPropertyListDisp(list)
      setOwnedPropertyPageCount(d.list.length == 0 ? [...Array(0)] : [...Array(Math.ceil(d.list.length/3))])
      // console.log("aaa")
      // console.log(list)
      // console.log(d.length == 0 ? [...Array(0)] : [...Array(Math.ceil(d.length/3))])
      // changeOwnedPropertyListDisp(d, 1)
      setDataLoading(1)
  }

  // 詳細個別
  const getOwnedPropertyKobetsuData = async (index, year, tatemono_id) => {

      var token = await GetAccessToken({ instance, inProgress, accounts }); 

      const f = await fetch(url + `/v2/t/owner/room/syousaikobetsu`, { method: 'post', body: JSON.stringify({year:year, tatemono_id: tatemono_id}), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
      const d = await f.json()

      // console.log(d)


      // setOwnedPropertyList(d.list)


      let newListArray = [...owned_property_list]
      newListArray[index] = d
      setOwnedPropertyList(newListArray)
      
      let newListArray2 = [...owned_property_list_disp]
      newListArray2[index] = d
      setOwnedPropertyListDisp(newListArray2)

      

      // setOwnedPropertyList(d.list)
      // setOwnedPropertyListDisp(list)

      // setDataLoading(1)
  }

  useEffect(() => {
        
    getOwnedPropertyListData()//物件取得

  }, [])

  return (

    <>


    {data_loading ? owned_property_list == 0 ? 
    <Box>
    <Box sx={{ }}>
        <Container sx={{}}>
          <ApartmentIcon sx={{ fontSize: 35, color: '#003366', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontSize: 22, fontWeight: 'bold', verticalAlign: 'middle'}}>所有物件</Box>
        </Container>
        <Container sx={{ p:5, ml:20 }}>
          所有物件はありません。
        </Container>
    </Box>
    </Box>
    :<Box>
    <Box sx={{  }}>
        <Container sx={{ml:0, mt:2}}>
          <ApartmentIcon sx={{ fontSize: 35, color: '#003366', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontSize: 22, fontWeight: 'bold', verticalAlign: 'middle'}}>所有物件</Box>
        </Container>
        <Container sx={{ textAlign: 'right', mr:0 }}>
          {owned_property_sousu.tatemono_count > 0 ? <Typography sx={{ fontSize: 20, mr: 3, fontFamily: 'Noto Sans JP' }}>
            所有物件数：{owned_property_sousu.tatemono_count}棟{owned_property_sousu.tatemono_kosu}戸
            (稼働率：{Math.round((owned_property_sousu.tatemono_kosu-owned_property_sousu.tatemono_aki) / owned_property_sousu.tatemono_kosu*1000)/10}%)
          </Typography>:""}
          {owned_property_sousu.p_count > 0 ? <Typography sx={{ fontSize: 20, mr: 3, fontFamily: 'Noto Sans JP' }}>
            所有パーキング数：{owned_property_sousu.p_count}箇所{owned_property_sousu.p_kosu}台
            (稼働率：{Math.round((owned_property_sousu.p_kosu-owned_property_sousu.p_aki) / owned_property_sousu.p_kosu*1000)/10}%)
          </Typography>:""}
        </Container>
    </Box>
    {/* <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={4}> */}
      <Swiper
        // dir="rtl"
        navigation={true}
        pagination={{
          clickable: true,
        }}
        // modules={[Navigation]}
        modules={[Navigation, Pagination]}
      >

      {owned_property_page_count.map(function (value, index) {

            return <SwiperSlide>
        {/* <Grid item xs={12} sm={6} md={4}> */}

        {[...Array(3)].map(function (value2, index2) {
          
          return <>
          {owned_property_list_disp[(index * 3) + (index2 + 1) - 1] ? 
          <Card variant="outlined" sx={{width: 450, height: 600}}>
          <CardHeader
            sx={{ pb: 0, whiteSpace:"break-space" }}
            avatar={
              <Avatar sx={{ width: 60, height: 60}} alt='tatemono_img' src={'https://img.global-center.co.jp/v2/tatemono/'+owned_property_list_disp[(index * 3) + (index2 + 1) - 1].tatemono_id.split(',')[0]+'/1/1/520/780/1'} />
            }
            title={owned_property_list_disp[(index * 3) + (index2 + 1) - 1].tatemono_name.split(',').map(function (item) {

              return <div>{item}</div> 

          }, this)}
            subheader={owned_property_list_disp[(index * 3) + (index2 + 1) - 1].add}
            titleTypographyProps={{
              fontSize: 20,
              fontWeight: 'bold',
              fontFamily: 'Noto Sans JP'
            }}
            subheaderTypographyProps={{
              fontSize: 17,
              color: 'black',
              fontFamily: 'Noto Sans JP'
            }}
          />
          <CardContent sx={{ pt: 0, pb: 0 }}>
            <Grid container sx={{justifyContent: 'center'}}>
                <Grid item sx={{alignSelf: 'center'}}>
                  {/* alignItems="center" */}
                    <Typography sx={{fontFamily: 'Noto Sans JP'}}>
                        <MeetingRoomIcon sx={{color: '#003366', verticalAlign: 'top', mr: 1, mb: 3 }} />
                        {owned_property_list_disp[(index * 3) + (index2 + 1) - 1].type == 1 ? <Box component='span' sx={{ fontSize: 17}}>入居数：{owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[0].value}/{(owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[0].value + owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[1].value)}戸</Box>
                        :<Box component='span' sx={{ fontSize: 17}}>入庫数：{owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[0].value}/{(owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[0].value + owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[1].value)}台</Box>}
                    
                    </Typography>
                    <Typography sx={{fontFamily: 'Noto Sans JP'}}>
                        <FlagCircleIcon sx={{color: '#003366', verticalAlign: 'top', mr: 1 }} />
                        {owned_property_list_disp[(index * 3) + (index2 + 1) - 1].type == 1 ? <Box component='span' sx={{ fontSize: 17}}>稼働率：{Math.round(owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[0].value / (owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[0].value + owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[1].value)*1000)/10}%</Box>
                        :<Box component='span' sx={{ fontSize: 17}}>稼働率：{Math.round(owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[0].value / (owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[0].value + owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[1].value)*1000)/10}%</Box>}                    
                    </Typography>
                </Grid>
                <Grid item>
                  <PieChart width={150} height={150}>
                    <Pie data={owned_property_list_disp[(index * 3) + (index2 + 1) - 1].type == 1 ? owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu : owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p} dataKey="value" cx="50%" cy="50%" innerRadius={40} outerRadius={60} startAngle={90} endAngle={-270} labelLine={false}>
                      {data_circle1.map((entry, index) =>
                        <Cell fill={COLORS[index]} />
                      )}
                      <Label
                        value={`${ owned_property_list_disp[(index * 3) + (index2 + 1) - 1].type == 1 ? ((owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[0].value / (owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[0].value + owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu[1].value) * 100).toFixed(0)) : ((owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[0].value / (owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[0].value + owned_property_list_disp[(index * 3) + (index2 + 1) - 1].kosu_p[1].value) * 100).toFixed(0))}%`}
                        position='center'
                        style={{fontSize: '20px', fill: 'black'}}
                      />
                    </Pie>
                  </PieChart>
                </Grid>
            </Grid>        
          </CardContent>
          <CardContent>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Button variant="contained" onClick={()=>getOwnedPropertyKobetsuData((index * 3) + (index2 + 1) - 1, moment(owned_property_list_disp[(index * 3) + (index2 + 1) - 1].year).add(-1, 'y').format("YYYY"), owned_property_list_disp[(index * 3) + (index2 + 1) - 1].tatemono_id)} disableElevation size="small" sx={{backgroundColor: '#003366', ':hover': {backgroundColor: '#316689'}, fontFamily: 'Noto Sans JP'}}>前年</Button>
                <Box component='span' sx={{ fontWeight: 'bold', fontSize: 22 }}>{owned_property_list_disp[(index * 3) + (index2 + 1) - 1].year}年</Box>
                <Button variant="contained" onClick={()=>getOwnedPropertyKobetsuData((index * 3) + (index2 + 1) - 1, moment(owned_property_list_disp[(index * 3) + (index2 + 1) - 1].year).add(1, 'y').format("YYYY"), owned_property_list_disp[(index * 3) + (index2 + 1) - 1].tatemono_id)} disableElevation size="small" sx={{backgroundColor: '#003366', ':hover': {backgroundColor: '#316689'}, fontFamily: 'Noto Sans JP'}}>次年</Button>
            </Box>
          </CardContent>
          <CardContent>
            <Typography sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{ fontSize: 17, fontFamily: 'Noto Sans JP' }}>年間収支</Box><Box sx={{ fontWeight: 'bold', fontSize: 20, fontFamily: 'Noto Sans JP' }} >{owned_property_list_disp[(index * 3) + (index2 + 1) - 1].sum_kingaku.toLocaleString()}円</Box>
            </Typography>
            {/* <Typography sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{ fontSize: 17 }}>9月収支</Box><Box sx={{ fontWeight: 'bold', fontSize: 20 }}>102,300円</Box>
            </Typography> */}
            <Typography sx={{textAlign: '-webkit-center'}}>
              <BarChart width={320} height={150} data={owned_property_list_disp[(index * 3) + (index2 + 1) - 1].tuki_kingaku} margin={{ top: 10, right: 10, left: 10 }}
                onMouseMove={state => {
                  if (state.isTooltipActive) {
                    setFocusBar1(state.activeTooltipIndex);
                    setFocusBarIndex1((index * 3) + (index2 + 1) - 1)
                  } else {
                    setFocusBar1(null);
                    setFocusBarIndex1(null);
                  }
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="month" />
                {/* <YAxis /> */}
                <Tooltip cursor={false} content={<CustomTooltip />} />
                {/* <Tooltip cursor={false} formatter={(value) => value.toLocaleString()} /> */}
                {/* <Legend /> */}
                <Bar dataKey="sokin" barSize={16}>
                  {data_bar.map((entry, index_data_bar) => (
                    <Cell key={`cell-${index_data_bar}`} fill={focusBar1 === index_data_bar && focusBarIndex1 == (index * 3) + (index2 + 1) - 1 ? '#83A3B8' : '#316689'} />
                  ))}
                </Bar>
              </BarChart>            
            </Typography>
          </CardContent>
          {/* <CardContent sx={{pt: 0}}>
            <Typography sx={{color:"text.secondary", fontSize: 14, textAlign:'right'}}>
              更新日：2023年1月20日
            </Typography>
          </CardContent> */}
          </Card>:
          <Card sx={{width: 460}}></Card>}
          </>
        })
      }

      {/* </Grid> */}
      </SwiperSlide>

       })
      }


   </Swiper>
  </Box>:<Loading />}

  </>
  );
}