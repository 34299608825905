import React, {createContext, useState, useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Title from '../static/image/title.png';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
// import Stack from '@mui/material/Stack';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Divider from '@mui/material/Divider';
import { useNavigate  } from 'react-router-dom';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import {UserContext,} from '../pages/index'

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const Header = () => {
  const navigate = useNavigate();
  const { instance, inProgress, accounts } = useMsal();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { user_detail } = useContext(UserContext);
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  
    setOpen(false);
  };

  
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }
  
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
  
    prevOpen.current = open;
  }, [open]);  

 

  return <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "white", boxShadow: "none", borderBottom: '1px solid lightgray', fontFamily: 'Noto Sans JP'}}>
    <Toolbar sx={{justifyContent: "space-between"}}>
      <Typography variant="h6" noWrap component="div">
        <img src={Title} alt="logo" width={200} style={{verticalAlign: 'bottom'}} onClick={()=>navigate('/gaiyou')}/>
      </Typography>

      <div>
        {user_detail.owner_id ? <AccountCircle style={{fontSize: 30, color:"#316689", verticalAlign: 'middle'}} />:""}
        {user_detail.owner_id ? <span style={{fontSize: 18, color: "black", marginRight: 30, marginLeft: 10, verticalAlign: 'middle'}}>{user_detail.owner_name}　様</span>:""}
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{color:"#316689"}}
        >
          <ArrowDropDownIcon style={{fontSize: 30}} />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {user_detail.owner_none ? "" : <><MenuItem onClick={() => navigate('/emailAddressHenkou')}><EmailIcon sx={{ fontSize: 30, color: '#316689', mr: 1 }} />メールアドレス変更</MenuItem>
                    <Divider />
                    <MenuItem onClick={() => navigate('/passwordHenkou')}><LockIcon sx={{ fontSize: 30, color: '#316689', mr: 1 }} />パスワード変更</MenuItem>
                    <Divider /></>}
                    <MenuItem onClick={() => navigate('/logout')}><ExitToAppIcon sx={{ fontSize: 30, color: '#316689', mr: 1 }} />ログアウト</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      {/* <div>
      <IconButton
        // size="large"
        // aria-label="account of current user"
        // aria-controls="menu-appbar"
        // aria-haspopup="true"
        // onClick={}
        style={{color:"#316689"}}
      >
        <AccountCircle />
      </IconButton><span style={{fontSize: 15, color: "black", marginRight: 30}}>グローバル住研株式会社　様</span>
      <IconButton
        style={{color:"#316689"}}
      >
        <SettingsIcon />
      </IconButton><span style={{fontSize: 15, color: "black"}}>設定</span>
      </div> */}
    </Toolbar>
  </AppBar>
};

export default Header;
