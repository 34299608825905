import Routers from './Routers';
import { useMsal, MsalProvider } from '@azure/msal-react';
import { EventType, InteractionRequiredAuthError, InteractionStatus, } from '@azure/msal-browser';
import { b2cPolicies } from './authConfig';
import { AuthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

function Pages(props) {
    
    const { instance, inProgress, accounts } = useMsal();
    const [account, setAccount] = React.useState(null);
    const activeAccount = instance.getActiveAccount();
    React.useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            
            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest)
                    .then(response => {
                        // console.log(response,"response")
                    })
                    .catch(err => {
                        // console.log(err,"error")
                    })
                }
            }
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                                account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };
                    instance.ssoSilent(signUpSignInFlowRequest);
                }
                // console.log(event.payload.idTokenClaims,"ろぐ")
                if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                    };
                    // instance.loginRedirect(signUpSignInFlowRequest)
                    instance.loginRedirect(signUpSignInFlowRequest)
                    .then(response => {
                        // console.log(response,"response")
                    })
                    .catch(err => {
                        // console.log(err,"error")
                    });
                }
            }
        });
        
    
        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);
    React.useEffect(() => {
        if(accounts[0]){
            
            var c = 0
            // console.log(accounts[0])
            if(accounts.length > 1){
                for(let i = 0;i < accounts.length;i++){
                    c = accounts[i].environment === b2cPolicies.authorityDomain ? i : c
                }
            }
            // const accessTokenRequest = {
            //     scopes: b2cPolicies.scopes,
            //     account: accounts[c],
            // };
            setAccount(accounts[c])
            // if (!token && inProgress === InteractionStatus.None) {
            // instance
            //     .acquireTokenSilent(accessTokenRequest)
            //     .then((accessTokenResponse) => {
            //         let accessToken = accessTokenResponse.accessToken;
            //         setToken(accessToken);
            //         // console.log(token,"トークン！")
            //     })
            //     .catch((error) => {
            //     if (error instanceof InteractionRequiredAuthError) {
            //         instance.acquireTokenRedirect(accessTokenRequest);
            //     }
            //         console.log(error);
            //     });
            // }
        }else{
            if(inProgress=='none'){
                const loginRequest = {
                    // redirectStartPage: window.location.hash,
                    onRedirectNavigate: () => true,
                    scopes: ['openid'],
                  };
                instance.loginRedirect(loginRequest)
            }
        }
    }, [instance, accounts, inProgress]);
    return (
        <>
            <AuthenticatedTemplate>
                { account ? (<Routers/>) : ''}
            </AuthenticatedTemplate>
        </>
    );
}
export const GetAccessToken = ({ instance, inProgress, accounts }) => {
    // const { instance, inProgress, accounts } = useMsal();
    if(accounts[0]){
        var c = 0
        if(accounts.length > 1){
            for(let i = 0;i < accounts.length;i++){
                c = accounts[i].environment === b2cPolicies.authorityDomain ? i : c
            }
        }
        const accessTokenRequest = {
            scopes: b2cPolicies.scopes,
            account: accounts[c],
        };
        if (inProgress === InteractionStatus.None) {
        return instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
                // let accessToken = accessTokenResponse.accessToken;
                return accessTokenResponse.accessToken;
            })
            .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }           
                // console.log(error);
            });
        }
    }else{
        if(inProgress=='none'){
            const loginRequest = {
                // redirectStartPage: window.location.hash,
                onRedirectNavigate: () => true,
                scopes: ['openid'],
              };
            instance.loginRedirect(loginRequest)
        }
    }
}
function App({ instance }){
    const gtmParams = { id: 'GTM-5LS4QPQF' }
    return (
        <GTMProvider state={gtmParams}>
            <MsalProvider instance={instance}>
                <Pages/>
            </MsalProvider>
        </GTMProvider>
      )
  };
export default App;