import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import MonitorIcon from '@mui/icons-material/Monitor';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import Loading from "../Layouts/loading";


const theme = createTheme({
    palette: {
      btn_page_color: {
        main: '#003366',
        contrastText: 'white',
      },
    },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const CleaningReportDetail = () => {

    const { instance, inProgress, accounts } = useMsal();
    const [data_loading, setDataLoading] = useState(0);//

    const [cleaning_report_list, setCleaningReportList] = useState([]);//リスト
    const [cleaning_report_list_disp, setCleaningReportListDisp] = useState([]);//リスト表示用
    const [cleaning_report_1page_count, setCleaningReport1PageCount] = useState(10);//リスト1ページに追加する件数
    const [cleaning_report_now_page, setCleaningReportNowPage] = useState(1);//リスト現在のページ
    const [cleaning_report_form, setCleaningReportFrom] = useState({ start_year: moment().format("YYYY"), start_month: moment().format("YYYY") == "2023" ? 12 : moment().format("M"), end_year: moment().format("YYYY"), end_month: moment().format("YYYY") == "2023" ? 12 : moment().format("M") })
    const [year_list, setYearList] = useState([]);//

    //一覧
    const getCleaningReportListData = async (owner_remittance_form) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v2/t/owner/room/cleaninglist`, { method: 'post', body: JSON.stringify(owner_remittance_form), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        d.sort(function (a, b) {
            if (a.so_ymd > b.so_ymd) {
                return -1;
            } else {
                return 1;
            }
        });

        setCleaningReportList(d)
        changeCleaningReportListDisp(d, 1)
        setDataLoading(1)
    }
    //送金　今のページのリストをセットする。
    const changeCleaningReportListDisp = async (list, count) => {
        let data = []
        for(let i = cleaning_report_1page_count * (count-1); i < list.length; i++){
            data.push(list[i])
            if(data.length == cleaning_report_1page_count){
                break
            }
        }
        setCleaningReportListDisp(data)
    }
    //送金 ページ変更時
    const handleChangeCleaningReportPage = async (value) => {
        setCleaningReportNowPage(value)
        changeCleaningReportListDisp(cleaning_report_list, value)// 今のページのリストをセットする。
    }
    //ダウンロード
    const downloadCleaningReport = async (tatemono_id, date) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v2/t/owner/room/cleaningdownload/`+tatemono_id+`/`+date, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.blob()

        checkOwnerDownload({ value: d.size })


        if (d.size) {
            if (d.size === 12) {
                // console.log(payload)
                // authentication.signOut();///////////////////////////////何かに置き換える。
            } else {
                // console.log(payload)

                // ダウンロード開始
                if (window.navigator.msSaveBlob) {//ie用なので
                    window.navigator.msSaveBlob(d, moment().format("YYYYMMDDHHmmss") + '.pdf');
                    checkOwnerDownload({ value: 1 })
                } else {

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    // ダウンロードされるファイル名
                    // a.download = action.payload.filename.replace("´", "");
                    a.download = moment().format("YYYYMMDDHHmmss") + '.pdf';
                    a.href = URL.createObjectURL(d);

                    checkOwnerDownload({ value: URL.createObjectURL(d) })
                    a.click();
                    // checkOwnerDownload({ value: 2 })
                    document.body.removeChild(a);
                }


            }
            //

        } else {
            checkOwnerDownload({ value: 3 })
            alert("ダウンロードできませんでした。")
        }

    }

    //ダウンロードチェック
    const checkOwnerDownload = async (values) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v1/owner/remittances/checkdownload/`, { method: 'post', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = f
        // console.log("ddd", d)

    }

    useEffect(() => {
    
        getCleaningReportListData(cleaning_report_form)//送金書一覧
        getYearList()//年リスト


    }, [])

    const getYearList = () => {
        
        let data = []
        for(let i=2023; i<=parseInt(moment().format("YYYY"))+1; i++){
            data.push(i)
        }
        setYearList(data)

    }
    
    const handleChange = (e) => {
        
        let newDetail = {...cleaning_report_form}
        newDetail[e.target.name] = e.target.value
        if(e.target.name == "start_year" && e.target.value == 2023) {
            newDetail["start_month"] = 12
        }else if(e.target.name == "end_year" && e.target.value == 2023) {
            newDetail["end_month"] = 12
        }
        
        setCleaningReportFrom(newDetail)

        // getOwnerRemittanceListData(newDetail)

    }

    const handleClickSearch = () => {
        
        getCleaningReportListData(cleaning_report_form)
        setCleaningReportNowPage(1)

    }

    const handlePdfOpen = (tatemono_id, date) => {
        window.open(`/PdfView/9/` + tatemono_id+`/` + date, '_blank', 'noopener')
    }


    return <Box><Box sx={{ flexGrow: 1 }}>
        <Box sx={{fontSize: 20, textAlign: 'left', ml: 4, mb: 5, display:"flex", justifyContent:"space-between"}}>
            <div>
                <AutoAwesomeIcon sx={{ fontSize: 35, color: '#003366', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>清掃報告</Box>
            </div>
            <div style={{display: 'inline-block'}}>
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="start_year_label">年</InputLabel>
                <Select
                    labelId="start_year_select_label"
                    id="start_year_select"
                    name="start_year"
                    value={cleaning_report_form.start_year}
                    label="年"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100 }} size="small">
                <InputLabel id="start_month_label">月</InputLabel>
                <Select
                    labelId="start_month_select_label"
                    id="start_month_select"
                    name="start_month"
                    value={cleaning_report_form.start_month}
                    label="月"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={1}>1月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={2}>2月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={3}>3月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={4}>4月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={5}>5月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={6}>6月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={7}>7月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={8}>8月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={9}>9月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={10}>10月</MenuItem>:null}
                    {cleaning_report_form.start_year > "2023" ? <MenuItem value={11}>11月</MenuItem>:null}
                    <MenuItem value={12}>12月</MenuItem>
                </Select>
            </FormControl>
            －
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="end_year_label">年</InputLabel>
                <Select
                    labelId="end_year_select_label"
                    name="end_year"
                    value={cleaning_report_form.end_year}
                    label="年"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="end_month_label">月</InputLabel>
                <Select
                    labelId="end_month_select_label"
                    id="end_month_select"
                    name="end_month"
                    value={cleaning_report_form.end_month}
                    label="月"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={1}>1月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={2}>2月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={3}>3月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={4}>4月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={5}>5月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={6}>6月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={7}>7月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={8}>8月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={9}>9月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={10}>10月</MenuItem>:null}
                    {cleaning_report_form.end_year > "2023" ? <MenuItem value={11}>11月</MenuItem>:null}
                    <MenuItem value={12}>12月</MenuItem>
                </Select>
            </FormControl>
            <ThemeProvider theme={theme}>
                <Button variant="contained" onClick={handleClickSearch} disableElevation color='btn_page_color' sx={{fontFamily: 'Noto Sans JP'}}>検 索</Button>
            </ThemeProvider>
            <div style={{fontSize:13}}>
                ※2023年11月以前の清掃報告書は送金書と一括して出力されます。
            </div>
            </div>
        </Box>
        {cleaning_report_list_disp.length > 0 ? <><Container sx={{ ml: 5 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', display: 'inline-block', width: 130, ml: 0, mr: 5, fontFamily: 'Noto Sans JP' }}>
                報告月
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', display: 'inline-block', fontFamily: 'Noto Sans JP' }}>
                建物名
            </Typography>
        </Container>
        <Divider /></>:""}
        {data_loading ? <List sx={{mt: 0, mb: 5}}>
            {cleaning_report_list_disp.length > 0 ? cleaning_report_list_disp.map(function (value, index) {
            return <>
            <ListItem sx={{pl: 8, height: 50, justifyContent: 'space-between'}}>
                <ListItemText
                    primary={moment(value.jissi_date+"/01").format("YYYY年MM月")}
                    secondary={value.tatemono_name}
                    primaryTypographyProps={{
                        width: 130,
                        fontWeight: 'medium',
                        mr: 5,
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP',
                    }}
                    secondaryTypographyProps={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP',
                        color: 'black'
                    }}
                />
                <ThemeProvider theme={theme}>
                    <Button variant="contained" onClick={()=>downloadCleaningReport(value.tatemono_id, moment(value.jissi_date+"/01").format("YYYYMM"))} disableElevation size="small" startIcon={<DownloadIcon />} color='btn_page_color'>ダウンロード</Button>
                    <Button variant="contained" onClick={()=>handlePdfOpen(value.tatemono_id, moment(value.jissi_date+"/01").format("YYYYMM"))} disableElevation size="small" startIcon={<MonitorIcon />} color='btn_page_color' style={{marginLeft: 10, marginRight: 50}}>表示</Button>
                </ThemeProvider>
            </ListItem>
            <Divider />
            </>
            }):
            <>
            <ListItem sx={{pl: 8, height: 50, justifyContent: 'space-between'}}>
                <ListItemText
                    primary="清掃報告書はありません。"
                    secondary=""
                    primaryTypographyProps={{
                        width: 250,
                        // color: 'primary',
                        fontWeight: 'medium',
                        mr: 5,
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP',
                    }}
                    secondaryTypographyProps={{
                        // color: 'primary',
                        fontWeight: 'medium',
                        variant: 'body2',
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP',
                    }}
                />
            </ListItem>
            </>
            }
        </List>:<Loading />}
    </Box>
    {cleaning_report_list.length > 0 && cleaning_report_list.length > cleaning_report_1page_count ? <Stack spacing={2} style={{alignItems: 'center'}}>
        <ThemeProvider theme={theme}>
            <Pagination onChange={(e,value)=>handleChangeCleaningReportPage(value)} count={Math.ceil(cleaning_report_list.length/cleaning_report_1page_count)} page={cleaning_report_now_page} color="btn_page_color" />
        </ThemeProvider>
    </Stack>:""}
    </Box>
};
  
export default CleaningReportDetail;