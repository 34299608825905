import React from 'react';
import { useLocation } from 'react-router-dom';
export const PageHook = () => {
    const location = useLocation();
    React.useEffect(()=>{
        window.dataLayer.push({
            event: 'pageview',
            page: location.pathname,
        });
    },[location.pathname])
}