import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Grid } from '@mui/material';
import Layout from '../Layouts/layout';
import LayoutMobile from '../Layouts/layoutMobile';
import FirstShounin from '../Components/firstShounin';
import FirstShouninMobile from '../Components/firstShouninMobile';
// import PasswordChangeMobile from '../Components/passwordChangeMobile';
import { PageHook } from '../PageHook';


const ShoakiShounin = () => {
  /**********必須**********/
  PageHook();
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const gamen_height = window.outerHeight - 56;

    return (
      isDesktop ? 
      <Box sx={{ display: 'flex' }}>
        <Layout />
        <Grid container spacing={2} style={{backgroundColor: 'lightgray', marginLeft: 0, marginTop: 65}}>
          <Grid item xs={12} md={12} sx={{backgroundColor: 'white', outline: 'auto', outlineColor: 'lightgray'}} style={{padding: '30px 30px 200px 30px'}}>
            <FirstShounin />
          </Grid>
        </Grid>        
      </Box>
      :
      <Box>
        <LayoutMobile />
        <Grid item xs={12} sx={{backgroundColor: 'white', outline: 'auto', outlineColor: 'lightgray', p: 2, height: gamen_height}}>
          <FirstShouninMobile />
        </Grid>
      </Box>
    );
}

export default ShoakiShounin;