import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import Loading from "../Layouts/loading";
import {UserContext,} from '../pages/index'
import Announcement from '../static/image/announcement_sp.png';


const theme = createTheme({
    palette: {
      page_color: {
        main: '#003366',
        contrastText: 'white',
      },
    },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const NoticeMobile = () => {

    const { instance, inProgress, accounts } = useMsal();
    const [data_loading, setDataLoading] = useState(0);//

    const [owner_message_count, setOwnerMessageCount] = useState(0);//お知らせリスト件数
    const [owner_message_list_disp, setOwnerMessageListDisp] = useState([]);//お知らせリスト表示用
    const [owner_message_1page_count, setOwnerMessage1PageCount] = useState(3);//お知らせリスト1ページに表示する件数
    const [owner_message_now_page, setOwnerMessageNowPage] = useState(1);//お知らせリスト現在のページ

    const { user_detail } = useContext(UserContext);

        // お知らせ
    const getOwnerMessageListData = async (now_page) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        var form = {owner_message_1page_count:owner_message_1page_count, owner_message_now_page: now_page, so_ymd:user_detail.so_ymd}
        const f = await fetch(url + `/v2/owner/messages/`, { method: 'post', body: JSON.stringify(form), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        let list = new Array()

        // let t = {
        //     type:1,
        //     from_owner_id: 0,
        //     to_owner_id: user_detail.owner_id, 
        //     message: "家賃送金締日変更のお知らせ",
        //     file_id: 0, 
        //     file_name: "家賃送金締日変更のお知らせ.pdf",
        //     so_ymd: "",
        //     created_at: "2023-12-04T00:00:00.000Z"
        // }
        let t = {
            type:1,
            from_owner_id: 0,
            to_owner_id: user_detail.owner_id, 
            message: "家賃送金締切日変更のお知らせ",
            file_id: 0, 
            file_name: "家賃送金締切日変更のお知らせ.pdf",
            so_ymd: "",
            created_at: "2024-04-01T00:00:00.000Z"
        }

        let c = 0
        
        // if(now_page == 1 && user_detail.so_ymd && user_detail.owner_id != "298" && (moment(user_detail.so_ymd).format("D") == 5 || moment(user_detail.so_ymd).format("D") == 10 || moment(user_detail.so_ymd).format("D") == 15 || moment(user_detail.so_ymd).format("D") == 20)) {
        //     t.file_id = moment(user_detail.so_ymd).format("D") == 5 ? 18 : 
        //                 moment(user_detail.so_ymd).format("D") == 10 ? 19 : 
        //                 moment(user_detail.so_ymd).format("D") == 15 ? 20 : 
        //                 moment(user_detail.so_ymd).format("D") == 20 ? 21 : 0

        if(now_page == 1 && user_detail.so_ymd && (moment(user_detail.so_ymd).format("D") == 5 || moment(user_detail.so_ymd).format("D") == 10 || moment(user_detail.so_ymd).format("D") == 15 || moment(user_detail.so_ymd).format("D") == 20)) {
            t.file_id = moment(user_detail.so_ymd).format("D") == 5 ? 24 :
                        moment(user_detail.so_ymd).format("D") == 10 ? 25 :
                        moment(user_detail.so_ymd).format("D") == 15 ? 26 : 
                        moment(user_detail.so_ymd).format("D") == 20 ? 27 : 0

            // list = list.concat(t)//家賃送金締日変更のお知らせを外すときはコメント
            // c = 1//家賃送金締日変更のお知らせを外すときはコメント
        } 

        setOwnerMessageListDisp(list.concat(d.list))
        // changeOwnerMessageListDisp(d, 1)
        setOwnerMessageCount(c + d.count)
        setDataLoading(1)
    }
    //お知らせ　今のページのリストをセットする。
    const changeOwnerMessageListDisp = async (list, count) => {
        let data = []
        for(let i = owner_message_1page_count * (count-1); i < list.length; i++){
            data.push(list[i])
            if(data.length == owner_message_1page_count){
                break
            }
        }
        setOwnerMessageListDisp(data)

    }
    //お知らせ ページ変更時
    const handleChangeOwnerMessagePage = async (value) => {
        setOwnerMessageNowPage(value)
        // changeOwnerMessageListDisp(owner_message_list, value)// 今のページのリストをセットする。
        getOwnerMessageListData(value)
    }
 //ダウンロード
    const downloadOwnerFile = async (value) => {
        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v2/owner/remittances/file/`+ value.id, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.blob()

        checkOwnerDownload({ value: d.size })

        if (d.size) {
            if (d.size === 12) {
                // console.log(payload)
                // authentication.signOut();///////////////////////////////何かに置き換える。
            } else {
                // console.log(payload)

                // ダウンロード開始
                if (window.navigator.msSaveBlob) {//ie用なので
                    window.navigator.msSaveBlob(d, value.filename);
                    checkOwnerDownload({ value: 1 })

                } else {

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    // ダウンロードされるファイル名
                    // a.download = action.payload.filename.replace("´", "");
                    a.download = value.filename;
                    a.href = URL.createObjectURL(d);

                    checkOwnerDownload({ value: URL.createObjectURL(d) })
                    a.click();
                    // yield put(check_owner_download({ value: 2 }));
                    document.body.removeChild(a);
                }

            }
            //

        } else {
            checkOwnerDownload({ value: 3 })
            alert("ダウンロードできませんでした。")
        }
    }
    //ダウンロードチェック
    const checkOwnerDownload = async (values) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v1/owner/remittances/checkdownload/`, { method: 'post', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = f
        // console.log("ddd", d)

    }
    useEffect(() => {
        
        getOwnerMessageListData(1)//お知らせ取得

    }, [])
 

    return (
    <Box sx={{p: 1}}>
        <Box sx={{fontSize: '20px'}}>
            <NotificationsIcon sx={{ fontSize: 35, color: '#003366', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>お知らせ</Box>
        </Box>
        <Box sx={{textAlign: 'center', paddingTop: 2}}>
            <img src={Announcement} alt="announcement" width={'100%'} onClick={()=>window.open('https://owner.global-center.co.jp/myWeb', '_blank')}/>
            {/* <img src={Announcement} alt="announcement"}} onClick={()=>navigate('/gaiyou')}/> */}
        </Box>
        {data_loading ? <Box>
        <List>
            {owner_message_list_disp.length > 0 ? owner_message_list_disp.map(function (value, index) {
            return <>
            <ListItem sx={{pl: 1, pr: 1, alignItems: 'start'}}>
                <ListItemText
                    primary={moment(value.created_at).utc().format("YYYY年M月D日")}
                    secondary={value.so_ymd ? 
                        moment(value.so_ymd).utc().format("YYYY年M月分の送金書が発行されました。") : 
                        <div style={{display: 'inline-flex'}}>
                            {value.message}
                            <IconButton onClick={()=>downloadOwnerFile({ id: value.file_id, filename: value.file_name })}><DownloadIcon /></IconButton>
                        </div>
                    }
                    sx={{display: 'contents'}}
                    primaryTypographyProps={{
                        width: 170,
                        fontSize: 14,
                        fontWeight: 'medium',
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        width: '-webkit-fill-available',
                        ml: 1,
                        fontFamily: 'Noto Sans JP',
                        color: 'black'
                    }}
                />
            </ListItem>
            <Divider />
            </>
            }):<>
            <ListItem sx={{pl: 1, pr: 1}}>
                <ListItemText
                    primary="お知らせはありません。"
                    secondary=""
                    sx={{display: 'flex'}}
                    primaryTypographyProps={{
                        width: 170,
                        fontSize: 15,
                        fontWeight: 'medium',
                        mr: 1,
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        fontWeight: 'medium',
                        fontFamily: 'Noto Sans JP'
                    }}
                />
            </ListItem>
            </>
            }

        </List>
        </Box>:<Loading />}
        {owner_message_count > 0 && owner_message_count > owner_message_1page_count ? <Stack spacing={2} sx={{alignItems: 'center'}}>
            <ThemeProvider theme={theme}>
                <Pagination count={Math.ceil(owner_message_count/owner_message_1page_count)} page={owner_message_now_page} color="page_color" onChange={(e,value)=>handleChangeOwnerMessagePage(value)} />
            </ThemeProvider>
        </Stack>:""}
        </Box>          
    )
};
  
export default NoticeMobile;