import Box from '@mui/material/Box';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';


const LogoutMobile = () => {
    return (
    <Box sx={{ mb: 30 }}>
        <Container sx={{ fontSize: 20, display: 'flex' }}>
            <ExitToAppIcon sx={{ fontSize: 35, color: '#316689', mr: 1 }} />
            <Container component='span' sx={{ fontWeight: 'bold', verticalAlign: 'middle', mt: 0.4 }}>ログアウト</Container>
        </Container>
        <Container sx={{ mt: 20 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, mb: 1 }}>
                ログアウトしました。
            </Typography>
        </Container>
    </Box>
    )
};
  
export default LogoutMobile;