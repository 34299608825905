import React from 'react';
import { useMsal } from '@azure/msal-react';
import { LogLevel } from '@azure/msal-browser';
//テスト用
// const initialize ={
//     instance: 'https://globalcenterowners.b2clogin.com/',
//     domain:'globalcenterowners.b2clogin.com',
//     tenant: 'globalcenterowners.onmicrosoft.com',
//     signInPolicy: 'B2C_1_owners_globalcenter',
//     resetPolicy: 'B2C_1_owners',
//     applicationId: '1704cc2b-e6db-462e-b24f-bd1db6137c91',
//     cacheLocation: 'sessionStorage',
//     scopes: ['https://globalcenterowners.onmicrosoft.com/265d9341-816c-4681-9b41-76ccea08b02f/read'],
//     redirectUri: process.env.REACT_APP_BASE_URL,
// }

// const initialize ={
//     instance: 'https://globalcenterowners.onmicrosoft.com/',
//     domain:'globalcenterowners.b2clogin.com',
//     tenant: 'globalcenterowners.onmicrosoft.com',
//     signInPolicy: 'B2C_1_owners_globalcenter',
//     resetPolicy: 'B2C_1_owners',
//     applicationId: '8ae0a3b8-f3fd-4705-b263-11ee9e3f924a',
//     cacheLocation: 'sessionStorage',
//     scopes: ['https://globalcenterowners.onmicrosoft.com/265d9341-816c-4681-9b41-76ccea08b02f/read'],
//     redirectUri: process.env.REACT_APP_BASE_URL,
// }

export const initialize ={
    instance: 'https://globalcenterowners.b2clogin.com/',
    domain:'globalcenterowners.b2clogin.com',
    tenant: 'globalcenterowners.onmicrosoft.com',
    signInPolicy: 'B2C_1_owners_globalcenter',
    resetPolicy: 'B2C_1_owners',
    applicationId: '8ae0a3b8-f3fd-4705-b263-11ee9e3f924a',
    cacheLocation: 'localStorage',
    scopes: ['https://globalcenterowners.onmicrosoft.com/265d9341-816c-4681-9b41-76ccea08b02f/read'],
    redirectUri: process.env.REACT_APP_BASE_URL,
}
export const b2cPolicies = {
    names: {
        signUpSignIn: initialize.signInPolicy,
        forgotPassword: initialize.resetPolicy,
        // editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
            authority:  `${initialize.instance}${initialize.tenant}/${initialize.signInPolicy}` ,
        },
        forgotPassword: {
            authority: `${initialize.instance}${initialize.tenant}/${initialize.resetPolicy}`,
        },
        // editProfile: {
        //     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
        // },
    },
    authorityDomain: initialize.domain,
    scopes:initialize.scopes,
};
export const msalConfig = {
    auth: {
        clientId: initialize.applicationId,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_BASE_URL,
        postLogoutRedirectUri: process.env.REACT_APP_BASE_URL,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};
export const getAcount = () => {
    // const user = .getAcount(b2cPolicies.scopes);
};
// export function GetToken() {
//     const { instance, inProgress, accounts } = useMsal();
//     alert(accounts[0])
//     console.log(accounts[0])
// }
export const loginRequest = {
    scopes: [],
};
export const silentRequest = {
    scopes: ["openid", "profile"],
};