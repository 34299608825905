import React, {createContext, useState, useContext} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './header';
// import LayoutMobile from './layoutMobile';
import Sidemenu from './sidemenu';
import {UserContext,} from '../pages/index'


const Layout = () => {

  // const user = useContext(UserContext)
  const { user_detail } = useContext(UserContext);
  
  return <div>
    <CssBaseline />
    <Header />
    {!user_detail.owner_none && user_detail.owner_id ? <Sidemenu /> : ""}
    {/* <LayoutMobile /> */}
  </div>
};

export default Layout;


// import * as React from 'react';
// import { ReactNode } from 'react';
// import Box from '@mui/material/Box';

// type Props = { children: ReactNode };

// const Layout = ({ children }: Props) => {
//   console.log(children)
//   return <Box sx={{ display: 'flex' }}><div id='aaa'>{children}あああ</div></Box>;
// };

// export default Layout;