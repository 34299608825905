import React, {useState, useContext, useEffect, useRef} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import EmailAddressCertification from './emailAddressCertification';

const theme = createTheme({
    palette: {
      btn_color: {
        main: 'black',
        contrastText: 'white',
      },
      textField_color: {
        main: '#316689'
      }
    },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const EmailAddChangeMobile = () => {
    const navigate = useNavigate();
    const { instance, inProgress, accounts } = useMsal();



/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
    const [new_mail_address, setNewMailAddress] = useState("");//メールアドレス
    const [make_code, setMakeCode] = useState(0);//コードが作れたか 1:作れた
    const [error_no, setErrorNo] = useState(0);//エラー番号

    useEffect(() => {
        
        setMakeCode(0)
    
    }, [])

    // コード送信
    const onClickSendCode = async () => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 

        const f = await fetch(url + `/v2/owner/change/portal/accounts/new`, { method: 'post', body: JSON.stringify({address: new_mail_address}), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        // console.log(d)

        if(d.res == 1){
            // navigate('/emailaddressNinsho')
            setMakeCode(1)
            setCode(["", "", "", "", "", ""])
            setErrorNo(0)
        }else{
            setErrorNo(d.res)
        }
        
    }

    const emailValidation = () => {

        // if (!value) return '※メールアドレスを入力してください';
        const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!regex.test(new_mail_address)) {
            setErrorNo(4)
        }else{
            setErrorNo(0)
            onClickSendCode()
        }
        // '※正しい形式でメールアドレスを入力してください';
        
    }


/////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
    const [code, setCode] = useState(["", "", "", "", "", ""])
    const [inputIndex, setIndex] = useState(0)
  
    const inputRef = [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ]
  
    const bsFunction = (event) => {
      if (event.key === "Backspace" && inputIndex > 0) {
        inputRef[inputIndex - 1].current.focus()
        setIndex(inputIndex - 1)
      }
    }
  
    useEffect(() => {
      document.addEventListener("keydown", bsFunction, false)
      return () => {
        document.removeEventListener("keydown", bsFunction, false)
      }
    }, [bsFunction])

    // コード送信
    const onClickCheckCode = async () => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 

        const f = await fetch(url + `/v2/owner/change/portal/accounts/check`, { method: 'post', body: JSON.stringify({address: new_mail_address, code:code[0]+code[1]+code[2]+code[3]+code[4]+code[5]}), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        // console.log(d)

        if(d.res == 1){
            // navigate('/emailaddressNinsho')
            setMakeCode(1)
            setErrorNo(0)

            ChangeNewAccount()

        }else{
            setErrorNo(d.res)
        }
        
    }

    // コード送信
    const ChangeNewAccount = async () => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 

        const f = await fetch(url + `/v2/password/signinid`, { method: 'post', body: JSON.stringify({address: new_mail_address}), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        // console.log(d)

        if(d.res == 5){
            navigate('/emailaddressKanryo')
        }else{
            // alert("NG")
            setErrorNo(d.res)
        }
        
    }


    return (
        <>
    {make_code == 1 ? <Box sx={{ mb: 30 }}>
        <Container sx={{ fontSize: 20, display: 'flex' }}>
            <EmailIcon sx={{ fontSize: 35, color: '#316689', mr: 1 }} />
            <Container component='span' sx={{ fontWeight: 'bold', verticalAlign: 'middle', mt: 0.4 }}>メールアドレス変更</Container>
        </Container>
        <Container sx={{ mt: 20, textAlign: 'center' }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, mb: 1, fontFamily: 'Noto Sans JP' }}>
                認証コードをご入力ください。
            </Typography>
            {[...Array(6)].map((_, i) => (
                <input className="certification_style"
                    maxLength={1}
                    key={i}
                    autoFocus={i === 0}
                    value={code[i]}
                    type="tel"
                    ref={inputRef[i]}
                    onChange={(e) => {
                    const codeArray = [
                        i !== 0 ? code[0] : e.target.value,
                        i !== 1 ? code[1] : e.target.value,
                        i !== 2 ? code[2] : e.target.value,
                        i !== 3 ? code[3] : e.target.value,
                        i !== 4 ? code[4] : e.target.value,
                        i !== 5 ? code[5] : e.target.value,
                    ]
                    setCode([...codeArray])
                    if (e.target.value !== "") {
                        i < 5 && inputRef[i + 1].current.focus()
                        setIndex(inputIndex + 1)
                    }
                }}
                />
            ))}
        </Container>
        <Container sx={{ mt: 7, textAlign: 'center' }}>
            <ThemeProvider theme={theme}>
                <Button 
                // onClick={() => navigate('/emailaddressKanryo')} 
                onClick={onClickCheckCode}
                variant="contained" disableElevation color='btn_color' sx={{ pl: 5, pr: 5, fontFamily: 'Noto Sans JP' }}>メールアドレスを認証する</Button>
            </ThemeProvider>
        </Container>
        {error_no > 1 ? <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>
            {error_no == 3 ? <span>※認証コードがただしくありません。<br></br>
                </span>:
            error_no == 6 ? <span>※認証できませんでした。<br></br>
                メールアドレスが正しいかご確認いただき、再度お試しください。
                </span>:
            ""}
        </Container>:""}
    </Box>
    :
    <Box sx={{ mb: 30 }}>
        <Container sx={{ fontSize: 20, display: 'flex' }}>
            <EmailIcon sx={{ fontSize: 35, color: '#316689', mr: 1 }} />
            <Container component='span' sx={{ fontWeight: 'bold', verticalAlign: 'middle', mt: 0.4 }}>メールアドレス変更</Container>
        </Container>
        <Container sx={{ mt: 20 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, mb: 1, fontFamily: 'Noto Sans JP' }}>
                新しく設定するメールアドレスを入力してください。
            </Typography>
            <ThemeProvider theme={theme}>
                <TextField id="outlined-basic" onChange={(e)=>setNewMailAddress(e.target.value)} variant="outlined" placeholder="例）claso@xxxxx.co.jp" size="small" color='textField_color' fullWidth />
            </ThemeProvider>
        </Container>
        <Container sx={{ mt: 2 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, fontFamily: 'Noto Sans JP' }}>
                ご入力いただいたメールに認証コードが送られます。<br></br>お間違えないようにご入力ください。
            </Typography>
        </Container>
        <Container sx={{ mt: 7, textAlign: 'center' }}>
            <ThemeProvider theme={theme}>
                <Button 
                // onClick={() => navigate('/emailaddressNinsho')} 
                onClick={emailValidation} disabled={!new_mail_address}
                variant="contained" disableElevation color='btn_color' sx={{ pl: 5, pr: 5, fontFamily: 'Noto Sans JP' }}>認証メールを送る</Button>
            </ThemeProvider>
        </Container>
        {error_no > 1 ? <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>
            {error_no == 2 ? <span>※認証コードが送信できませんでした。<br></br>
                メールアドレスが正しいかご確認いただき、再度お試しください。
            </span>:
            error_no == 4 ? <span>※メールアドレスが正しくありません。<br></br>
        </span>:
        ""}
        </Container>:""}
    </Box>
    }
    </>
    )
};
  
export default EmailAddChangeMobile;