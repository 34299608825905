import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useNavigate  } from 'react-router-dom';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import {UserContext,} from '../pages/index'

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const theme = createTheme({
    palette: {
      btn_color: {
        main: 'black',
        contrastText: 'white',
      },
      textField_color: {
        main: '#316689'
      }
    },
});

const PasswordChange = () => {
    const navigate = useNavigate();

    const { instance, inProgress, accounts } = useMsal();

    const [show_password, setShowPassword] = useState("");
    const [portal_password, setPortalPassword] = useState("");
    const [portal_tel, setPortalTel] = useState("");

    const [check_owner_auth_alert, setCheckOwnerAuthAlert] = useState(0);//エラー
    const [check_owner_auth_first, setCheckOwnerAuthFirst] = useState(0);//初回であること
    const [check_owner_auth_loading, setCheckOwnerAuthLOADING] = useState(false);//処理中であること
    const [check_owner_id, setCheckOwnerId] = useState(0);//
    const [check_owner_tel, setCheckOwnerTel] = useState({ tel: "", telcount: 0 });//

    const [new_pass1, setNewPass1] = useState("");
    const [new_pass2, setNewPass2] = useState("");
    const [pass_confirm, setPassConfirm] = useState(0);
    const [password_confirm_loading, setChangePasswordConfirmLoading] = useState(false);

    // const user = useContext(UserContext)
    const { user_detail, updateFirstLoginUserDetail } = useContext(UserContext);

    React.useEffect(() => {
    
        CheckAuth()//
    
    }, []);  

    const CheckAuth = async() => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v1/tm/owner/auth/count`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        setCheckOwnerAuthAlert(d.count)
        setCheckOwnerAuthFirst(1)

    }
    

    const onClickPassSend = async() => {
        
        setCheckOwnerAuthLOADING(true)
        AuthPass()
    }

    const AuthPass = async() => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        let value = { portal_password: portal_password }
        const f = await fetch(url + `/v1/tm/owner/authpass`, { method: 'post', body: JSON.stringify(value), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        if (d.error) {
            // yield put(change_owner_page(2));
            setCheckOwnerAuthAlert(d.error);
            setCheckOwnerAuthFirst(2);

        } else {

            // yield put(change_owner_page(4));
            setCheckOwnerAuthFirst(3);
            setCheckOwnerAuthAlert(0);

            setCheckOwnerId(d.owner_id);
            setCheckOwnerTel({ tel: d.tel, telcount: Number(d.telcount) });

        }

        setCheckOwnerAuthLOADING(false);

    }

    const onClickTelSend = async() => {
        
        setCheckOwnerAuthLOADING(true)
        AuthTel()
    }

    const AuthTel = async() => {


        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        let value = { owner_id: check_owner_id, portal_tel: portal_tel }
        const f = await fetch(url + `/v1/tm/owner/authtel`, { method: 'post', body: JSON.stringify(value), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        if (d.error) {

            // yield put(change_owner_page(4));
            setCheckOwnerAuthAlert(d.error);
            setCheckOwnerAuthFirst(3);
            // alert("1")


        } else {
            
            // yield put(change_owner_page(1));
            setCheckOwnerAuthAlert(0);
            // yield put(Master.change_owner(d));
            updateFirstLoginUserDetail()
            
            // alert("2")

        }

        setCheckOwnerAuthLOADING(false);

    }


    return (
    <Box>
        <Container sx={{fontSize: 20, textAlign: 'left', ml: 4, mb: 5}}>
            <LockIcon sx={{ fontSize: 35, color: '#316689', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>認証パスワード確認</Box>
        </Container>




        {check_owner_auth_alert > 10 ? 
            
            <>
                <Container sx={{mt: 20, textAlign: '-webkit-center'}}>
                <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>
                    ※認証パスワードを規定数以上間違えたためロックされました。<br />株式会社グローバルセンターTEL0800-200-0090までお問い合わせください。
                    </Container>
                    {/* <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>※認証パスワードを規定数以上間違えたためロックされました。<br />株式会社グローバルセンターTEL0800-200-0090までお問い合わせください。</Container> */}
                </Container>
            </>

                
        : 
            check_owner_auth_first === 3 ? 
                <>


                            {check_owner_tel.telcount ? <><Container sx={{mt: 20, textAlign: '-webkit-center'}}>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, mb: 1 }}>
                                        株式会社グローバルセンターに登録されている<b>電話番号</b>を入力してください。<br />
                                        <b>{check_owner_tel.telcount === 10 ? " ※※※-※※※-※※" + check_owner_tel.tel : "※※※-※※※※-※※" + check_owner_tel.tel}</b>

                                    </Typography>
                                    <TextField onChange={(e)=>setPortalTel(e.target.value)} style={{ margin: "20px 0 10px 0" }} id="new_tel" label="電話番号を入力" type="text" value={portal_tel} variant="outlined" size="small" /><br></br>

                                </Container>
                                {check_owner_auth_alert > 5 ? 
                                    <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>※電話番号が間違っています。あと{11 - check_owner_auth_alert}回間違えるとログインできなくなります。</Container>
                                    : 
                                        check_owner_auth_first === 3 && check_owner_auth_alert > 0 ? 
                                        <Container sx={{mt: 1, textAlign: 'center', color:"red"}}> ※電話番号が間違っています。</Container>
                                        : ""}

                                <Container sx={{mt: 7, textAlign: 'center'}}>
                                    <ThemeProvider theme={theme}>
                                        <Button disabled={check_owner_auth_loading} onClick={onClickTelSend} variant="contained" disableElevation color='btn_color' sx={{pl: 5, pr: 5}}>送信する</Button>
                                    </ThemeProvider>
                                </Container>

                                </> : 
                                
                                <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>
                                        ※認証用の電話番号が株式会社グローバルセンターに登録されていません。<br />株式会社グローバルセンター<b>TEL0800-200-0090</b>までお問い合わせください。
                                </Container>}

                </> 



    : 
    check_owner_auth_first > 0 ? 
        <>
            <Container sx={{mt: 20, textAlign: '-webkit-center'}}>
                <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, mb: 1 }}>
                    株式会社グローバルセンターより送付された「認証パスワード」を入力してください。
                </Typography>
                <ThemeProvider theme={theme}>
                    <OutlinedInput 
                        id="outlined-basic" 
                        value={portal_password} 
                        onChange={(e)=>setPortalPassword(e.target.value)} 
                        // variant="outlined" 
                        // size="small" 
                        color='textField_color' 
                        sx={{width: 500}} 
                        type={show_password? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!show_password)}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {show_password ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </ThemeProvider>
            </Container>
            
            <Container sx={{mt: 7, textAlign: 'center'}}>
                <ThemeProvider theme={theme}>
                    <Button disabled={check_owner_auth_loading} onClick={onClickPassSend} variant="contained" disableElevation color='btn_color' sx={{pl: 5, pr: 5}}>送信する</Button>
                    {/* <Button disabled={this.props.check_owner_auth_loading} style={{ marginLeft: "8px", color: "white", backgroundColor: "#337ab7" }} variant="contained" size="small" onClick={this.check_owner_auth}>送信</Button> */}
                </ThemeProvider>
            </Container>

            {check_owner_auth_alert > 5 ? 
            <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>※認証パスワードが間違っています。あと{11 - check_owner_auth_alert}回間違えるとログインできなくなります。</Container>
            : 
                check_owner_auth_first === 2 && check_owner_auth_alert > 0 ? 
                    <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>※認証パスワードが間違っています。</Container>
                : 
                    ""
            }
        </>:""}
    </Box>
    )
};
  
export default PasswordChange;