import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import MonitorIcon from '@mui/icons-material/Monitor';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DescriptionIcon from '@mui/icons-material/Description';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import Loading from "../Layouts/loading";
import '../styles/list.css';


const theme = createTheme({
    palette: {
      btn_page_color: {
        main: '#003366',
        contrastText: 'white',
      },
    },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const ElectronicContractDetail = () => {

    const { instance, inProgress, accounts } = useMsal();
    const [data_loading, setDataLoading] = useState(0);//

    const [electronic_contract_count, setElectronicContractCount] = useState([]);//件数
    const [electronic_contract_list_disp, setElectronicContractListDisp] = useState([]);//送金リスト表示用
    const [electronic_contract_1page_count, setElectronicContract1PageCount] = useState(10);//送金リスト1ページに追加する件数
    const [electronic_contract_now_page, setElectronicContractNowPage] = useState(1);//送金リスト現在のページ
    const [electronic_contract_form, setElectronicContractFrom] = useState({ start_year: moment().format("YYYY"), start_month: moment().format("YYYY") == "2023" ? 12 : moment().format("M"), end_year: moment().format("YYYY"), end_month: moment().format("YYYY") == "2023" ? 12 : moment().format("M") })
    const [electronic_contract_url, setElectronicContractUrl] = useState("");//送金書表示
    const [year_list, setYearList] = useState([]);//送金書表示

//送金書一覧
    const getElectronicContractListData = async (electronic_contract_form, now_page) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        let form = electronic_contract_form
        form["electronic_contract_1page_count"] = electronic_contract_1page_count
        form["electronic_contract_now_page"] = now_page
        const f = await fetch(url + `/v2/t/owner/room/contractlist`, { method: 'post', body: JSON.stringify(form), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        // d.sort(function (a, b) {
        //     if (a.so_ymd > b.so_ymd) {
        //         return -1;
        //     } else {
        //         return 1;
        //     }
        // });
        setElectronicContractCount(d.count)
        // changeElectronicContractListDisp(d, 1)
        setElectronicContractListDisp(d.list)
        setDataLoading(1)
    }
    //今のページのリストをセットする。
    const changeElectronicContractListDisp = async (list, count) => {
        let data = []
        for(let i = electronic_contract_1page_count * (count-1); i < list.length; i++){
            data.push(list[i])
            if(data.length == electronic_contract_1page_count){
                break
            }
        }
        setElectronicContractListDisp(data)
    }
    //ページ変更時
    const handleChangeElectronicContractPage = async (value) => {
        setElectronicContractNowPage(value)
        // changeElectronicContractListDisp(electronic_contract_list, value)// 今のページのリストをセットする。
        getElectronicContractListData(electronic_contract_form, value)
    }
    //ダウンロード
    const downloadElectronicContract = async (id, type) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(
            type == 1 ? url + `/v2/t/owner/room/contractdownloadr/`+type+`/`+id ://契約書
            type == 2 ? url + `/v2/t/owner/room/contractdownloadr/`+type+`/`+id ://重説
            url + `/v2/t/owner/room/contractdownloadp/`+id, {//P
            method: 'get',
            // body: JSON.stringify(search),
            headers: {'Authorization': 'Bearer ' + token, 'Content-type': 'application/pdf; charset=UTF-8'},
  
        }).then(response => response.blob())
  
        .then((d) => {

            if (d.size) {
                if (d.size === 12) {
                    // console.log(payload)
                    // authentication.signOut();///////////////////////////////何かに置き換える。
                } else {
                    // console.log(payload)

                    // ダウンロード開始
                    if (window.navigator.msSaveBlob) {//ie用なので
                        window.navigator.msSaveBlob(d, "aaa");
                        // checkOwnerDownload({ value: 1 })
                    } else {

                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        // ダウンロードされるファイル名
                        // a.download = action.payload.filename.replace("´", "");
                        a.download = type == 1 ?"賃貸借契約書":type == 2 ?"重要事項説明書":"駐車場契約書";
                        a.href = URL.createObjectURL(d);

                        // checkOwnerDownload({ value: URL.createObjectURL(d) })
                        a.click();
                        // checkOwnerDownload({ value: 2 })
                        document.body.removeChild(a);
                    }


                }
                //

            } else {
                // checkOwnerDownload({ value: 3 })
                alert("ダウンロードできませんでした。")
            }

        })


    }

    useEffect(() => {
    
        getElectronicContractListData(electronic_contract_form, 1)//送金書一覧
        getYearList()//年リスト


    }, [])

    const getYearList = () => {
        
        let data = []
        for(let i=2023; i<=parseInt(moment().format("YYYY"))+1; i++){
            data.push(i)
        }
        setYearList(data)
    }
    
    const handleChange = (e) => {
        
        let newDetail = {...electronic_contract_form}
        newDetail[e.target.name] = e.target.value
        if(e.target.name == "start_year" && e.target.value == 2023) {
            newDetail["start_month"] = 12
        }else if(e.target.name == "end_year" && e.target.value == 2023) {
            newDetail["end_month"] = 12
        }
        
        setElectronicContractFrom(newDetail)

        // getOwnerRemittanceListData(newDetail)

    }

    const handleClickSearch = () => {
        
        getElectronicContractListData(electronic_contract_form, 1)
        setElectronicContractNowPage(1)

    }

    //ダウンロード 表示
    const handlePdfOpen = (id, type) => {
        window.open(`/PdfView/`+type+`/` + id+`/1`, '_blank', 'noopener')
    }

    return <Box>
        <Box sx={{ flexGrow: 1 }}>
        {/* <Box className="electronic_contract_list" sx={{ flexGrow: 1 }}> */}
        <Box sx={{fontSize: 20, textAlign: 'left', mb: 2, ml: 4}}>
            <ContactPageIcon sx={{ fontSize: 35, color: '#003366', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>賃貸借契約</Box>
        </Box>

        <Box sx={{display: 'inline-block', ml: 4}}>
        <Box sx={{textAlign: 'left'}}>
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="start_year_label">年</InputLabel>
                <Select
                    labelId="start_year_select_label"
                    id="start_year_select"
                    name="start_year"
                    value={electronic_contract_form.start_year}
                    label="年"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100 }} size="small">
                <InputLabel id="start_month_label">月</InputLabel>
                <Select
                    labelId="start_month_select_label"
                    id="start_month_select"
                    name="start_month"
                    value={electronic_contract_form.start_month}
                    label="月"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={1}>1月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={2}>2月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={3}>3月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={4}>4月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={5}>5月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={6}>6月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={7}>7月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={8}>8月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={9}>9月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={10}>10月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={11}>11月</MenuItem>:null}
                    <MenuItem value={12}>12月</MenuItem>
                </Select>
            </FormControl>
            －
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="end_year_label">年</InputLabel>
                <Select
                    labelId="end_year_select_label"
                    name="end_year"
                    value={electronic_contract_form.end_year}
                    label="年"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="end_month_label">月</InputLabel>
                <Select
                    labelId="end_month_select_label"
                    id="end_month_select"
                    name="end_month"
                    value={electronic_contract_form.end_month}
                    label="月"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={1}>1月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={2}>2月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={3}>3月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={4}>4月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={5}>5月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={6}>6月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={7}>7月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={8}>8月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={9}>9月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={10}>10月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={11}>11月</MenuItem>:null}
                    <MenuItem value={12}>12月</MenuItem>
                </Select>
            </FormControl>
            <ThemeProvider theme={theme}>
                <Button variant="contained" onClick={handleClickSearch} disableElevation color='btn_page_color' sx={{ fontFamily: 'Noto Sans JP' }}>検 索</Button>
            </ThemeProvider>
        </Box>
        <Box>
            ※2023年11月以前の清掃報告書は送金書と一括して出力されます。
        </Box>
        </Box>

        {/* <Box sx={{display: 'inline-block', ml: 4}}>
        <Box sx={{textAlign: 'left'}}>
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="start_year_label">年</InputLabel>
                <Select
                    labelId="start_year_select_label"
                    id="start_year_select"
                    name="start_year"
                    value={electronic_contract_form.start_year}
                    label="年"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100 }} size="small">
                <InputLabel id="start_month_label">月</InputLabel>
                <Select
                    labelId="start_month_select_label"
                    id="start_month_select"
                    name="start_month"
                    value={electronic_contract_form.start_month}
                    label="月"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={1}>1月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={2}>2月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={3}>3月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={4}>4月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={5}>5月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={6}>6月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={7}>7月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={8}>8月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={9}>9月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={10}>10月</MenuItem>:null}
                    {electronic_contract_form.start_year > "2023" ? <MenuItem value={11}>11月</MenuItem>:null}
                    <MenuItem value={12}>12月</MenuItem>
                </Select>
            </FormControl>
            －
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="end_year_label">年</InputLabel>
                <Select
                    labelId="end_year_select_label"
                    id="end_year_select"
                    name="end_year"
                    value={electronic_contract_form.end_year}
                    label="年"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="end_month_label">月</InputLabel>
                <Select
                    labelId="end_month_select_label"
                    id="end_month_select"
                    name="end_month"
                    value={electronic_contract_form.end_month}
                    label="月"
                    onChange={handleChange}
                    sx={{fontFamily: 'Noto Sans JP'}}
                >
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={1}>1月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={2}>2月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={3}>3月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={4}>4月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={5}>5月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={6}>6月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={7}>7月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={8}>8月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={9}>9月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={10}>10月</MenuItem>:null}
                    {electronic_contract_form.end_year > "2023" ? <MenuItem value={11}>11月</MenuItem>:null}
                    <MenuItem value={12}>12月</MenuItem>
                </Select>
            </FormControl>
            <ThemeProvider theme={theme}>
                <Button variant="contained" onClick={handleClickSearch} disableElevation color='btn_page_color' sx={{ fontFamily: 'Noto Sans JP' }}>検 索</Button>
            </ThemeProvider>
        </Box>
        <Box>
            ※2023年11月以前の賃貸借契約書は送金書と一括して出力されます。
        </Box>
        </Box> */}
        {electronic_contract_list_disp.length > 0 ? <Box><Box sx={{ml: 4, mt: 5}}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', display: 'inline-block', fontFamily: 'Noto Sans JP'}}>
                契約日　建物名　契約者
            </Typography>
        </Box>
        <Divider /></Box>:""}
        {data_loading ? <List sx={{mt: 0, mb: 5}}>
            {electronic_contract_list_disp.length > 0 ? electronic_contract_list_disp.map(function (value, index) {
            return <>
            <ListItem sx={{pt: 0}}>
                
                {/* <ListItemIcon>
                    <div style={{fontSize:13, width:70, color:"red"}}>{!value.cloudsign_completed_at ? "電子契約" : ""}</div>
                </ListItemIcon> */}
                <ListItemText
                    primary={
                        <Box>
                            <Container sx={{fontSize:13, color:"red", pl: 0}}>{value.cloudsign_completed_at ? "電子契約" : ""}</Container>
                            <Container sx={{pl: 0, fontFamily: 'Noto Sans JP'}}>{moment(value.contract_date).format("YYYY年M月D日")}</Container>
                        </Box>
                    }
                    secondary={
                            <ListItemText
                                sx={{pl: 3}}
                                primary={value.tatemono_name + " " + value.room_name}
                                secondary={value.name1}
                                primaryTypographyProps={{
                                    width: 240,
                                    fontWeight: 'bold',
                                    variant: 'body2',
                                    // display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Noto Sans JP',
                                    color: 'black'
                                }}
                                secondaryTypographyProps={{
                                    width: 240,
                                    fontWeight: 'bold',
                                    variant: 'body2',
                                    // display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Noto Sans JP',
                                    color: 'black'
                                }}
                            />
                    }
                    // primaryTypographyProps={{
                    //     width: 180,
                    //     fontWeight: 'medium',
                    //     // display: 'inline-block'
                    // }}
                    // secondaryTypographyProps={{
                    //     fontWeight: 'bold',
                    //     // display: 'inline-block'
                    // }}
                />

                <ThemeProvider theme={theme}>
                    {value.counting==1?
                    <div>
                        <div style={{marginBottom:2}}>
                            <Button variant="contained" style={{width:"140px", fontFamily: 'Noto Sans JP'}} onClick={()=>downloadElectronicContract(value.rent_contract_id, 1)} disableElevation size="small" startIcon={<DownloadIcon />} color='btn_page_color'>賃貸借契約書</Button>
                            <Button variant="contained" onClick={()=>handlePdfOpen(value.rent_contract_id, 3)} disableElevation size="small" startIcon={<MonitorIcon />} color='btn_page_color' style={{marginLeft: 10, fontFamily: 'Noto Sans JP'}}>表示</Button>
                        </div>
                        <div>
                            <Button variant="contained" style={{width:"140px", fontFamily: 'Noto Sans JP'}} onClick={()=>downloadElectronicContract(value.rent_contract_id, 2)} disableElevation size="small" startIcon={<DownloadIcon />} color='btn_page_color'>重要事項説明書</Button>
                            <Button variant="contained" onClick={()=>handlePdfOpen(value.rent_contract_id, 4)} disableElevation size="small" startIcon={<MonitorIcon />} color='btn_page_color' style={{marginLeft: 10, fontFamily: 'Noto Sans JP'}}>表示</Button>
                        </div>
                    </div>:
                    <div>
                        <div  style={{marginBottom:2}}>
                            <Button variant="contained" style={{width:"140px", fontFamily: 'Noto Sans JP'}} onClick={()=>downloadElectronicContract(value.rent_contract_id, 3)} disableElevation size="small" startIcon={<DownloadIcon />} color='btn_page_color'>賃貸借契約書</Button>
                            <Button variant="contained" onClick={()=>handlePdfOpen(value.rent_contract_id, 6)} disableElevation size="small" startIcon={<MonitorIcon />} color='btn_page_color' style={{marginLeft: 10, fontFamily: 'Noto Sans JP'}}>表示</Button>
                        </div>
                    </div>
                    }
                </ThemeProvider>
            </ListItem>
            <Divider />
            </>
            }):
            <>
            <ListItem sx={{pl: 8, pt: 10, height: 50, justifyContent: 'space-between'}}>
                <ListItemText
                    primary="賃貸借契約はありません。"
                    secondary=""
                    primaryTypographyProps={{
                        width: 250,
                        // color: 'primary',
                        fontWeight: 'medium',
                        mr: 5,
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        // color: 'primary',
                        fontWeight: 'medium',
                        variant: 'body2',
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP'
                    }}
                />
            </ListItem>
            </>
            }
        </List>:<Loading />}
    </Box>
    {electronic_contract_count > 0 && electronic_contract_count > electronic_contract_1page_count ? <Stack spacing={2} style={{alignItems: 'center'}}>
        <ThemeProvider theme={theme}>
            <Pagination onChange={(e,value)=>handleChangeElectronicContractPage(value)} count={Math.ceil(electronic_contract_count/electronic_contract_1page_count)} page={electronic_contract_now_page} color="btn_page_color" />
        </ThemeProvider>
    </Stack>:""}
    </Box>
};
  
export default ElectronicContractDetail;