import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';


const EmailAddressComp = () => {
    return (
    <Box>
        <Container sx={{fontSize: 20, textAlign: 'left', ml: 4, mb: 5}}>
            <EmailIcon sx={{ fontSize: 35, color: '#316689', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>メールアドレス変更</Box>
        </Container>
        <Container sx={{mt: 20, textAlign: '-webkit-center'}}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, width: 500, mb: 1 }}>
                メールアドレスの変更が完了しました。<br></br>引き続きGLOBALオーナー様 my web をご利用ください。
            </Typography>
        </Container>
    </Box>
    )
};
  
export default EmailAddressComp;