import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Avatar from '@mui/material/Avatar';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import Loading from "../Layouts/loading";


const theme = createTheme({
    palette: {
      btn_color: {
        main: '#003366',
        contrastText: 'white',
      },
      list_btn_color: {
        main: 'black',
        contrastText: 'white',
      },
      page_color: {
        main: '#003366',
        contrastText: 'white',
      },
    },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const OwnedProperty = () => {

    const { instance, inProgress, accounts } = useMsal();
    const [data_loading, setDataLoading] = useState(0);//

    const [owned_property_list, setOwnedPropertyList] = useState([]);//物件リスト
    const [owned_property_list_disp, setOwnedPropertyListDisp] = useState([]);//物件リスト表示用
    const [owned_property_1page_count, setOwnedProperty1PageCount] = useState(5);//物件リスト1ページに表示する件数
    const [owned_property_now_page, setOwnedPropertyNowPage] = useState(1);//物件リスト現在のページ

        // お知らせ
    const getOwnedPropertyListData = async () => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 

        const f = await fetch(url + `/v2/t/owner/room/`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()
        setOwnedPropertyList(d)
        changeOwnedPropertyListDisp(d, 1)
        setDataLoading(1)
    }
    //お知らせ　今のページのリストをセットする。
    const changeOwnedPropertyListDisp = async (list, count) => {
        let data = []
        for(let i = owned_property_1page_count * (count-1); i < list.length; i++){
            data.push(list[i])
            if(data.length == owned_property_1page_count){
                break
            }
        }
        setOwnedPropertyListDisp(data)

    }
    //お知らせ ページ変更時
    const handleChangeOwnedPropertyPage = async (value) => {
        setOwnedPropertyNowPage(value)
        changeOwnedPropertyListDisp(owned_property_list, value)// 今のページのリストをセットする。
    }

    useEffect(() => {
        
        getOwnedPropertyListData()//物件取得

    }, [])

    return (
    <Box>
        <Box sx={{fontSize: '20px'}}>
            <ApartmentIcon sx={{ fontSize: 35, color: '#003366', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>所有物件</Box>
        </Box>
        {data_loading ? <List>
            {owned_property_list_disp.length > 0 ? owned_property_list_disp.map(function (value, index) {
            return <>
            <ListItem style={{paddingTop: 4, paddingBottom: 4 }}>
                <Box sx={{ width: 100, textAlign: '-webkit-center' }}>
                    <Avatar sx={{ width: 55, height: 55 }} alt='tatemono_img' src={'https://img.global-center.co.jp/v2/tatemono/'+value.tatemono_id+'/1/1/520/780/1'} />
                </Box>
                <ListItemText
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    primary={value.tatemono_name ? value.tatemono_name : value.p_name}
                    secondary={value.add ? value.add : value.p_add}
                    primaryTypographyProps={{
                        width: 180,
                        // marginLeft: 10,
                        fontWeight: 'bold',
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        width: 180,
                        marginLeft: 2,
                        fontSize: 15,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'Noto Sans JP',
                        color: 'black'
                    }}
                />
            </ListItem>
            <Divider />
            </>
            }):<>
            <ListItem className='list_style'>
                <ListItemText
                    primary="所有物件はありません。"
                    secondary=""
                    sx={{display: 'contents'}}
                    className='date_text_size'
                    primaryTypographyProps={{
                        fontWeight: 'medium',
                        marginRight: 10,
                        width:200,
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        fontWeight: 'medium',
                        fontFamily: 'Noto Sans JP'
                    }}
                />
            </ListItem>
            </>
            }
            {/* <ListItem style={{paddingTop: 3, paddingBottom: 3 }}>
                <Box sx={{ width: 150, textAlign: '-webkit-center' }}>
                    <Avatar sx={{ width: 55, height: 55 }} alt='tatemono_img' src='https://img.global-center.co.jp/v2/tatemono/100443/1/1/520/780/1' />
                </Box>
                <ListItemText
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    primary="サムティ栗林公園北"
                    secondary="香川県高松市中野町 1-1"
                    primaryTypographyProps={{
                        // width: 400,
                        marginLeft: 10,
                    }}
                    secondaryTypographyProps={{
                        width: 200,
                        color: 'black',
                        marginRight: 5,
                        fontSize: 15,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                />
            </ListItem>
            <Divider />
            <ListItem style={{paddingTop: 3, paddingBottom: 3 }}>
                <Box sx={{ width: 150, textAlign: '-webkit-center' }}>
                    <Avatar sx={{ width: 55, height: 55 }} alt='tatemono_img' src='https://img.global-center.co.jp/v2/tatemono/100443/1/1/520/780/1' />
                </Box>
                <ListItemText
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    primary="サムティ栗林公園北"
                    secondary="香川県高松市中野町 1-1"
                    primaryTypographyProps={{
                        // width: 400,
                        marginLeft: 10,
                    }}
                    secondaryTypographyProps={{
                        width: 200,
                        color: 'black',
                        marginRight: 5,
                        fontSize: 15,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                />
            </ListItem> */}
        </List>:<Loading />}
        {owned_property_list.length > 0 && owned_property_list.length > owned_property_1page_count ? <Stack spacing={2} style={{alignItems: 'center'}}>
            <ThemeProvider theme={theme}>
                {/* <Pagination count={9} color="page_color" /> */}
                <Pagination count={Math.ceil(owned_property_list.length/owned_property_1page_count)} page={owned_property_now_page} color="page_color" onChange={(e,value)=>handleChangeOwnedPropertyPage(value)} />
            </ThemeProvider>
        </Stack>:""}
    </Box>
    )
};
  
export default OwnedProperty;