import React, {createContext, useState, useContext} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Grid } from '@mui/material';
import Layout from '../Layouts/layout';
import LayoutMobile from '../Layouts/layoutMobile';
import { GetAccessToken } from '../App'

import '../styles/layout.css';
import '../styles/top.css';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useLocation } from 'react-router-dom';

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

export const UserContext = createContext()

export const Index = ({ children }) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 980px)' })
    const isPad = useMediaQuery({ query: '(min-width: 768px)' })

    const { instance, inProgress, accounts } = useMsal();
    const [user_detail, setUserDetail] = React.useState(false);
    const navigate = useNavigate();

    
    React.useEffect(() => {
    
    if(accounts && accounts.find(a => a.environment == 'globalcenterowners.b2clogin.com')){
      getUserData()//ログイン情報
    }
    }, [accounts]);  
  
    //通常ログイン時
    const getUserData = async () => {
  
      var token = await GetAccessToken({ instance, inProgress, accounts }); 
      if(token){
        const f = await fetch(url + `/v2/tm/owner/detail`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()
    
        setUserDetail(d)
        // console.log("owner", d)

        if(d.owner_none){
          navigate('/shokaiShounin')
        }
      }
  }

  // const updateUserDetail = (newValue) => {
  //   setUserDetail(newValue);
  // };

  //初回のパス確認が終わった後
  const updateFirstLoginUserDetail = async() => {
    var token = await GetAccessToken({ instance, inProgress, accounts }); 
      
    const f = await fetch(url + `/v1/tm/owner/`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
    const d = await f.json()

    setUserDetail(d)
    // console.log("owner", d)

    if(d.owner_none){
      navigate('/shokaiShounin')
    }else{
      navigate('/gaiyou')
    }
  };
    
    return (
      <UserContext.Provider value={{user_detail, updateFirstLoginUserDetail}}>
        {children}
      </UserContext.Provider>
    );
}

// export const useAuth = () => {
//   return useContext(UserContext);
// };

