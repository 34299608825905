import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import MonitorIcon from '@mui/icons-material/Monitor';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import GetAppIcon from '@mui/icons-material/GetApp';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import Loading from "../Layouts/loading";


const theme = createTheme({
    palette: {
      btn_page_color: {
        main: '#003366',
        contrastText: 'white',
      },
    },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const TenantRequestDetail = () => {

    const { instance, inProgress, accounts } = useMsal();
    const [data_loading, setDataLoading] = useState(0);//

    const [tenant_request_list, setTenantRequestList] = useState([]);//送金リスト
    const [tenant_request_list_disp, setTenantRequestListDisp] = useState([]);//送金リスト表示用
    const [tenant_request_1page_count, setTenantRequest1PageCount] = useState(10);//送金リスト1ページに追加する件数
    const [tenant_request_now_page, setTenantRequestNowPage] = useState(1);//送金リスト現在のページ
    const [tenant_request_form, setTenantRequestFrom] = useState({ date:"2023/09/01" })
    const [tenant_request_url, setTenantRequestUrl] = useState("");//送金書表示
    const [year_list, setYearList] = useState([]);//送金書表示

    //送金書一覧
    const getTenantRequestListData = async (tenant_request_form) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 

        const f = await fetch(url + `/v2/t/owner/room/syoyulistdate`, { method: 'post', body: JSON.stringify(tenant_request_form), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        setTenantRequestList(d)
        changeTenantRequestListDisp(d, 1)
        setDataLoading(1)
    }
    //送金　今のページのリストをセットする。
    const changeTenantRequestListDisp = async (list, count) => {
        let data = []
        for(let i = tenant_request_1page_count * (count-1); i < list.length; i++){
            data.push(list[i])
            if(data.length == tenant_request_1page_count){
                break
            }
        }
        setTenantRequestListDisp(data)
    }
    //送金 ページ変更時
    const handleChangeTenantRequestPage = async (value) => {
        setTenantRequestNowPage(value)
        changeTenantRequestListDisp(tenant_request_list, value)// 今のページのリストをセットする。
    }
    //ダウンロード
    const downloadTenantRequest = async (value) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v2/owner/remittances/request/`+value.id+`/20230901`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.blob()

        checkOwnerDownload({ value: d.size })


        if (d.size) {
            if (d.size === 12) {
                // console.log(payload)
                // authentication.signOut();///////////////////////////////何かに置き換える。
            } else {
                // console.log(payload)

                // ダウンロード開始
                if (window.navigator.msSaveBlob) {//ie用なので
                    window.navigator.msSaveBlob(d, "定期報告書");
                    checkOwnerDownload({ value: 1 })
                } else {

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    // ダウンロードされるファイル名
                    // a.download = action.payload.filename.replace("´", "");
                    a.download = "定期報告書";
                    a.href = URL.createObjectURL(d);

                    checkOwnerDownload({ value: URL.createObjectURL(d) })
                    a.click();
                    checkOwnerDownload({ value: 2 })
                    document.body.removeChild(a);
                }


            }
            //

        } else {
            checkOwnerDownload({ value: 3 })
            alert("ダウンロードできませんでした。")
        }

    }

    //ダウンロードチェック
    const checkOwnerDownload = async (values) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v1/owner/remittances/checkdownload/`, { method: 'post', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = f
        // console.log("ddd", d)

    }

    useEffect(() => {
    
        getTenantRequestListData(tenant_request_form)//送金書一覧
        getYearList()//年リスト


    }, [])

    const getYearList = () => {
        
        let data = []
        for(let i=2018; i<=parseInt(moment().format("YYYY"))+1; i++){
            data.push(i)
        }
        setYearList(data)
    }
    
    const handleChange = (e) => {
        
        let newDetail = {...tenant_request_form}
        newDetail[e.target.name] = e.target.value
        
        setTenantRequestFrom(newDetail)

        // getOwnerRemittanceListData(newDetail)

    }

    const handleClickSearch = () => {
        
        getTenantRequestListData(tenant_request_form)
        setTenantRequestNowPage(1)
    }

    const handlePdfOpen = (id) => {
        window.open(`/PdfView/2/` + id+`/1`, '_blank', 'noopener')
    }


    return <Box>
        <Box sx={{ flexGrow: 1 }}>
        <Box sx={{fontSize: 20, textAlign: 'left', ml: 4, mb: 5}}>
            <SmsFailedIcon sx={{ fontSize: 35, color: '#003366', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>入居者リクエスト</Box>
            {/* <div style={{display: 'inline-block', float: 'right'}}>
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="start_year_label">年</InputLabel>
                <Select
                    labelId="start_year_select_label"
                    id="start_year_select"
                    name="start_year"
                    value={tenant_request_form.start_year}
                    label="年"
                    onChange={handleChange}
                >
                    {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100 }} size="small">
                <InputLabel id="start_month_label">月</InputLabel>
                <Select
                    labelId="start_month_select_label"
                    id="start_month_select"
                    name="start_month"
                    value={tenant_request_form.start_month}
                    label="月"
                    onChange={handleChange}
                >
                    <MenuItem value={1}>1月</MenuItem>
                    <MenuItem value={2}>2月</MenuItem>
                    <MenuItem value={3}>3月</MenuItem>
                    <MenuItem value={4}>4月</MenuItem>
                    <MenuItem value={5}>5月</MenuItem>
                    <MenuItem value={6}>6月</MenuItem>
                    <MenuItem value={7}>7月</MenuItem>
                    <MenuItem value={8}>8月</MenuItem>
                    <MenuItem value={9}>9月</MenuItem>
                    <MenuItem value={10}>10月</MenuItem>
                    <MenuItem value={11}>11月</MenuItem>
                    <MenuItem value={12}>12月</MenuItem>
                </Select>
            </FormControl>
            －
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="end_year_label">年</InputLabel>
                <Select
                    labelId="end_year_select_label"
                    id="end_year_select"
                    name="end_year"
                    value={tenant_request_form.end_year}
                    label="年"
                    onChange={handleChange}
                >
                    {year_list.length > 0 ? year_list.map(function (value, index) {
                        return <MenuItem value={value}>{value}年</MenuItem>
                    }):null}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100, marginRight: 1 }} size="small">
                <InputLabel id="end_month_label">月</InputLabel>
                <Select
                    labelId="end_month_select_label"
                    id="end_month_select"
                    name="end_month"
                    value={tenant_request_form.end_month}
                    label="月"
                    onChange={handleChange}
                >
                    <MenuItem value={1}>1月</MenuItem>
                    <MenuItem value={2}>2月</MenuItem>
                    <MenuItem value={3}>3月</MenuItem>
                    <MenuItem value={4}>4月</MenuItem>
                    <MenuItem value={5}>5月</MenuItem>
                    <MenuItem value={6}>6月</MenuItem>
                    <MenuItem value={7}>7月</MenuItem>
                    <MenuItem value={8}>8月</MenuItem>
                    <MenuItem value={9}>9月</MenuItem>
                    <MenuItem value={10}>10月</MenuItem>
                    <MenuItem value={11}>11月</MenuItem>
                    <MenuItem value={12}>12月</MenuItem>
                </Select>
            </FormControl>
            <ThemeProvider theme={theme}>
                <Button variant="contained" onClick={handleClickSearch} disableElevation color='btn_page_color'>検 索</Button>
            </ThemeProvider>
            </div> */}
        </Box>
        {tenant_request_list_disp.length > 0 ? <><Container sx={{ ml: 5 }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', display: 'inline-block', width: 130, ml:0, mr: 5, fontFamily: 'Noto Sans JP' }}>
                報告月
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', display: 'inline-block', fontFamily: 'Noto Sans JP' }}>
                建物名
            </Typography>
        </Container>
        <Divider /></>:""}
        {data_loading ? <List sx={{mt: 0, mb: 5}}>
            {tenant_request_list_disp.length > 0 ? tenant_request_list_disp.map(function (value, index) {
            return <>
            <ListItem sx={{pl: 8, justifyContent: 'space-between'}}>
                <ListItemText
                    primary={"2023年9月"}
                    secondary={value.tatemono_name}
                    primaryTypographyProps={{
                        width: 130,
                        fontWeight: 'medium',
                        mr: 5,
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        fontWeight: 'bold',
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP',
                        color: 'black'
                    }}
                />
                <ThemeProvider theme={theme}>
                    <div>
                        <div style={{marginBottom:2}}>
                            <Button onClick={()=>downloadTenantRequest({ id: value.tatemono_id })} variant="contained" disableElevation size="small" startIcon={<DownloadIcon />} color='btn_page_color' style={{fontFamily: 'Noto Sans JP'}}>ダウンロード</Button>
                            <Button onClick={()=>handlePdfOpen(value.tatemono_id)} variant="contained" disableElevation size="small" startIcon={<MonitorIcon />} color='btn_page_color' style={{marginLeft: 10, marginRight: 50, fontFamily: 'Noto Sans JP'}}>表示</Button>
                        </div>    
                    </div>
                </ThemeProvider>
            </ListItem>
            <Divider />
            </>
            }):
            <>
            <ListItem sx={{pl: 8, height: 50, justifyContent: 'space-between'}}>
                <ListItemText
                    primary="入居者リクエストはありません。"
                    secondary=""
                    primaryTypographyProps={{
                        width: 250,
                        // color: 'primary',
                        fontWeight: 'medium',
                        mr: 5,
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        // color: 'primary',
                        fontWeight: 'medium',
                        variant: 'body2',
                        display: 'inline-block',
                        fontFamily: 'Noto Sans JP'
                    }}
                />
            </ListItem>
            </>
            }

        </List>:<Loading />}
    </Box>
    {tenant_request_list.length > 0 && tenant_request_list.length > tenant_request_1page_count ? <Stack spacing={2} style={{alignItems: 'center'}}>
        <ThemeProvider theme={theme}>
            <Pagination onChange={(e,value)=>handleChangeTenantRequestPage(value)} count={Math.ceil(tenant_request_list.length/tenant_request_1page_count)} page={tenant_request_now_page} color="btn_page_color" />
        </ThemeProvider>
    </Stack>:""}
    </Box>
};
  
export default TenantRequestDetail;