import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Gaiyou from "./pages/gaiyou";
import SoukinMeisai from "./pages/soukinMeisai";
import DenshiKeiyaku from "./pages/denshiKeiyaku";
import SeisouHoukoku from "./pages/seisouHoukoku";
import PMHoukoku from "./pages/pmHoukoku";
import NyukyoshaRequest from "./pages/nyukyoshaRequest";
import ShoyuBukken from "./pages/shoyuBukken";
import EmailAddressHenkou from "./pages/emailAddressHenkou";
import EmailAddressNinsho from "./pages/emailAddressNinsho";
import EmailAddressKanryo from "./pages/emailAddressKanryo";
import PasswordHenkou from "./pages/passwordHenkou";
import PasswordKanryo from "./pages/passwordKanryo";
import ShokaiShounin from "./pages/shokaiShounin";
import Logout from "./pages/logout";
import PdfView from "./pages/pdfView";
import { Index } from "./pages/index";
// import { AuthProvider } from './AuthContext';

const Routers = () => {
    return (
        <Router>

                <Index>
                <Routes>
                    <Route path="/" element={<Gaiyou />}/>
                    <Route path="/gaiyou" element={<Gaiyou />}/>
                    <Route path="/soukinMeisai" element={<SoukinMeisai />}/>
                    <Route path="/denshiKeiyaku" element={<DenshiKeiyaku />}/>
                    <Route path="/seisouHoukoku" element={<SeisouHoukoku />}/>
                    <Route path="/pmHoukoku" element={<PMHoukoku />}/>
                    <Route path="/nyukyoshaRequest" element={<NyukyoshaRequest />}/>
                    <Route path="/shoyuBukken" element={<ShoyuBukken />}/>
                    <Route path="/emailAddressHenkou" element={<EmailAddressHenkou />}/>
                    <Route path="/emailAddressKanryo" element={<EmailAddressKanryo />}/>
                    <Route path="/passwordHenkou" element={<PasswordHenkou />}/>
                    <Route path="/passwordKanryo" element={<PasswordKanryo />}/>
                    <Route path="/shokaiShounin" element={<ShokaiShounin />}/>
                    <Route path="/logout" element={<Logout />}/>

                    <Route path='/PdfView/:type/:id/:date' element={<PdfView />} />
                    </Routes>
                </Index>
            
        </Router>
    )
}
export default Routers