import React, {useState, useContext, useEffect} from 'react';

import '../styles/Loading.css';

const Loading = () => {
   

    return (
    

            <div >
               
               <div className="cv-spinner">
                    <span className="spinner"></span>
                    </div>
                

            </div>
        )
    };
      
export default Loading;

