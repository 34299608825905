import Box from '@mui/material/Box';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig,b2cPolicies,initialize } from '../authConfig';
import React from 'react';

const Logout = () => {
    return (
    <Box>
        <Container sx={{fontSize: 20, textAlign: 'left', ml: 4, mb: 5}}>
            <ExitToAppIcon sx={{ fontSize: 35, color: '#316689', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>ログアウト</Box>
        </Container>
        <Container sx={{mt: 20, textAlign: '-webkit-center'}}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, width: 500, mb: 1 }}>
                ログアウトしました。
            </Typography>
        </Container>
    </Box>
    )
};
  

export default Logout;