import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useNavigate  } from 'react-router-dom';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const theme = createTheme({
    palette: {
      btn_color: {
        main: 'black',
        contrastText: 'white',
      },
      textField_color: {
        main: '#316689'
      }
    },
});

const PasswordChange = () => {
    const navigate = useNavigate();

    const { instance, inProgress, accounts } = useMsal();

    const [new_pass1, setNewPass1] = useState("");
    const [new_pass2, setNewPass2] = useState("");
    const [pass_confirm, setPassConfirm] = useState(0);
    const [password_confirm_loading, setChangePasswordConfirmLoading] = useState(false);

    const [show_password1, setShowPassword1] = useState("");
    const [show_password2, setShowPassword2] = useState("");

    const updatePass = async() => {


        var token = await GetAccessToken({ instance, inProgress, accounts }); 

        let value={new:new_pass1, confirm:new_pass2}
        const f = await fetch(url + `/v1/password`, {
            method: 'post',
            body: JSON.stringify(value),
            headers: {'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8'},
        })
        .then(res => res.json())
        .then((payload) => {
            setPassConfirm(payload.res)

            if(payload.res === 5){
                navigate('/passwordKanryo')
            }
            setChangePasswordConfirmLoading(false)
        })

        .catch(error => ({ error }));
    }

    const onClickUpdatePass = async() => {
        setChangePasswordConfirmLoading(true)
        checkPass()
    }

    const checkPass = async() => {
        
        if(new_pass1 !== new_pass2){
            setPassConfirm(1)
            setChangePasswordConfirmLoading(false)
        }else if(!new_pass1){
            setPassConfirm(2)
            setChangePasswordConfirmLoading(false)
        }else if(new_pass1.length < 8 || new_pass1.length > 16){
            setPassConfirm(3)
            setChangePasswordConfirmLoading(false)
        }else{
            updatePass()
        }
    }

    return (
    <Box>
        <Container sx={{fontSize: 20, textAlign: 'left', ml: 4, mb: 5}}>
            <LockIcon sx={{ fontSize: 35, color: '#316689', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>パスワード変更</Box>
        </Container>
        <Container sx={{mt: 20, textAlign: '-webkit-center'}}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, width: 500, mb: 1, fontFamily: 'Noto Sans JP' }}>
                新しく設定するパスワードを入力してください。
            </Typography>
            <ThemeProvider theme={theme}>
                {/* <TextField id="outlined-basic" value={new_pass1} onChange={(e)=>setNewPass1(e.target.value)} variant="outlined" size="small" color='textField_color' sx={{width: 500}} /> */}
                <OutlinedInput 
                        id="outlined-basic" 
                        value={new_pass1} 
                        onChange={(e)=>setNewPass1(e.target.value)}
                        color='textField_color' 
                        sx={{width: 500}} 
                        type={show_password1? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword1(!show_password1)}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {show_password1 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
            
            
            </ThemeProvider>
        </Container>
        <Container sx={{mt: 4, textAlign: '-webkit-center'}}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, width: 500, mb: 1, fontFamily: 'Noto Sans JP' }}>
                確認のため2回ご入力ください。
            </Typography>
            <ThemeProvider theme={theme}>
                {/* <TextField id="outlined-basic" value={new_pass2} onChange={(e)=>setNewPass2(e.target.value)} variant="outlined" size="small" color='textField_color' sx={{width: 500}} /> */}
                <OutlinedInput 
                        id="outlined-basic" 
                        value={new_pass2} 
                        onChange={(e)=>setNewPass2(e.target.value)}
                        color='textField_color' 
                        sx={{width: 500}} 
                        type={show_password2? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword2(!show_password2)}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {show_password2 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
            
            </ThemeProvider>
        </Container>
        <Container sx={{mt: 7, textAlign: 'center'}}>
            <ThemeProvider theme={theme}>
                <Button disabled={password_confirm_loading} onClick={() => onClickUpdatePass()} variant="contained" disableElevation color='btn_color' sx={{pl: 5, pr: 5, fontFamily: 'Noto Sans JP'}}>パスワードを変更する</Button>
            </ThemeProvider>
        </Container>
        {pass_confirm > 0 ? <Container sx={{mt: 1, textAlign: 'center', color:"red"}}>
            {pass_confirm == 1 ? "※確認用パスワードと一致していません。" : 
            pass_confirm == 2 ? "※パスワードを入力してください。" : 
            pass_confirm == 3 ? "※8文字から 16文字でパスワードを設定してください。" :
            pass_confirm == 4 ? "※8文字から 16文字でパスワードを設定してください。<br />小文字、大文字、数字 (0-9)、記号のうち 3 つを使って作成する必要があります。" :
            ""}
        </Container>:""}
    </Box>
    )
};
  
export default PasswordChange;