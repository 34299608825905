import React, {createContext, useState, useContext} from 'react';
// import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate  } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import {UserContext,} from '../pages/index'

import '../styles/layout.css';
import Title from '../static/image/title.png';


const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function LayoutMobile() {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { user_detail } = useContext(UserContext);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const url = process.env.REACT_APP_API_BASE_URL
  const { instance, inProgress, accounts } = useMsal();


  return (
    <Box sx={{mt: 7}}>
      <AppBar position="fixed" open={open} sx={{backgroundColor: "white"}}>
        <Toolbar sx={{justifyContent: 'space-between'}}>
          <img src={Title} alt="logo" width={170} onClick={()=>navigate('/gaiyou')} />
          <IconButton
            // color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{...(open && { display: 'none'}), color: "#316689"}}
          >
            <MenuIcon sx={{fontSize: "2rem"}} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        anchor="right"
        variant='temporary'
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader sx={{fontSize: 14, backgroundColor: '#003366'}}>
          <IconButton onClick={handleDrawerClose} sx={{p: 0}}>
            <ChevronRightIcon sx={{color: 'white'}} />
          </IconButton>
          <AccountCircleIcon sx={{ fontSize: 30, color: 'white', mr: 1 }} />
          <Box component='span' sx={{fontSize: 14, color: 'white'}}>
            {user_detail.owner_name}
          </Box>
          {user_detail.owner_id ? <Box component='span' sx={{fontSize: 14, color: 'white'}}>　様</Box>:""}
        </DrawerHeader>
        <Divider />
        {user_detail.owner_id ? <List>
          {['概　　要', '送金明細', '賃貸借契約', '清掃報告', 'BM 報 告', '入居者ﾘｸｴｽﾄ', '所有物件'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => index === 0 ? (navigate('/gaiyou'), handleDrawerClose) : index === 1 ? (handleDrawerClose, navigate('/soukinMeisai')) : index === 2 ? (handleDrawerClose, navigate('/denshiKeiyaku')) : index === 3 ? (handleDrawerClose, navigate('/seisouHoukoku')) : index === 4 ? (handleDrawerClose, navigate('/pmHoukoku')) : index === 5 ? (handleDrawerClose, navigate('/nyukyoshaRequest')) : index === 6 ? (handleDrawerClose, navigate('/shoyuBukken')) : ''}
              >
              {index === 0 ? <HomeIcon sx={{ fontSize: 30, color: '#003366', mr: 1 }} />
              : index === 1 ? <ArticleIcon sx={{ fontSize: 30, color: '#003366', mr: 1 }} />
              : index === 2 ? <ContactPageIcon sx={{ fontSize: 30, color: '#003366', mr: 1 }} />
              : index === 3 ? <AutoAwesomeIcon sx={{ fontSize: 30, color: '#003366', mr: 1 }} />
              : index === 4 ? <EngineeringIcon sx={{ fontSize: 30, color: '#003366', mr: 1 }} />
              : index === 5 ? <SmsFailedIcon sx={{ fontSize: 30, color: '#003366', mr: 1 }} />
              : index === 6 ? <ApartmentIcon sx={{ fontSize: 30, color: '#003366', mr: 1 }} /> : ""}
                <ListItemText primary={text} primaryTypographyProps={{ fontFamily: 'Noto Sans JP' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>:""}
        {user_detail.owner_id ? <Divider />:""}
        {user_detail.owner_id ? <List>
          {['メールアドレス変更', 'パスワード変更', 'ログアウト'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => index === 0 ? (handleDrawerClose, navigate('/emailAddressHenkou')) : index === 1 ? (handleDrawerClose, navigate('/passwordHenkou')) : index === 2 ? (handleDrawerClose, navigate('/logout')) : ''}
              >
              {index === 0 ? <EmailIcon sx={{ fontSize: 30, color: '#316689', mr: 1 }} />
              : index === 1 ? <LockIcon sx={{ fontSize: 30, color: '#316689', mr: 1 }} />
              : index === 2 ? <ExitToAppIcon sx={{ fontSize: 30, color: '#316689', mr: 1 }} /> : ""}
                <ListItemText primary={text} primaryTypographyProps={{ fontFamily: 'Noto Sans JP' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        :
        <List>
          {['ログアウト'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => (handleDrawerClose, navigate('/logout'))}
              >
                <ExitToAppIcon sx={{ fontSize: 30, color: '#316689', mr: 1 }} />
                <ListItemText primary={text} primaryTypographyProps={{ fontFamily: 'Noto Sans JP' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>}
      </Drawer>
    </Box>
  );
}