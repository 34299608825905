import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import MonitorIcon from '@mui/icons-material/Monitor';
import ArticleIcon from '@mui/icons-material/Article';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useNavigate, useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import Loading from "../Layouts/loading";

const theme = createTheme({
    palette: {
      btn_color: {
        main: '#003366',
        contrastText: 'white',
      },
      list_btn_color: {
        main: 'black',
        contrastText: 'white',
      },
      page_color: {
        main: '#003366',
        contrastText: 'white',
      },
    },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const RemittanceMobile = () => {

    const navigate = useNavigate();
    const { instance, inProgress, accounts } = useMsal();
    const [data_loading, setDataLoading] = useState(0);//

    const [owner_remittance_list, setOwnerRemittanceList] = useState([]);//送金リスト
    const [owner_remittance_list_disp, setOwnerRemittanceListDisp] = useState([]);//送金リスト表示用
    const [owner_remittance_1page_count, setOwnerRemittance1PageCount] = useState(5);//送金リスト1ページに追加する件数
    const [owner_remittance_now_page, setOwnerRemittanceNowPage] = useState(1);//送金リスト現在のページ
    const [owner_remittance_form, setOwnerRemittanceFrom] = useState({ 
        start_year: moment("2018/01/01").format("YYYY"), 
        start_month: moment("2018/01/01").format("M"), 
        end_year: moment().format("YYYY"), 
        end_month: moment().format("M"), 
        saisin:1, owner_remittance_1page_count:1000, owner_remittance_now_page:1 })
    const [owner_remittances_url, setOwnerRemittanceUrl] = useState("");//送金書表示

        //送金書一覧
    const getOwnerRemittanceListData = async () => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v2/owner/remittances/`, { method: 'post', body: JSON.stringify(owner_remittance_form), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        d.list.sort(function (a, b) {
            if (a.so_ymd > b.so_ymd) {
                return -1;
            } else {
                return 1;
            }
        });

        setOwnerRemittanceList(d.list)
        changeOwnerRemittanceListDisp(d.list, 1)
        setDataLoading(1)
    }
    //送金　今のページのリストをセットする。
    const changeOwnerRemittanceListDisp = async (list, count) => {
        let data = []
        for(let i = owner_remittance_1page_count * (count-1); i < list.length; i++){
            data.push(list[i])
            if(data.length == owner_remittance_1page_count){
                break
            }
        }
        setOwnerRemittanceListDisp(data)
    }
    //送金 ページ変更時
    const handleChangeOwnerRemittancePage = async (value) => {
        setOwnerRemittanceNowPage(value)
        changeOwnerRemittanceListDisp(owner_remittance_list, value)// 今のページのリストをセットする。
    }
    //ダウンロード
    const downloadOwnerRemittance = async (id) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v2/owner/remittances/download/`+id, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.blob()

        checkOwnerDownload({ value: d.size })

        if (d.size) {
            if (d.size === 12) {
                // console.log(payload)
                // authentication.signOut();///////////////////////////////何かに置き換える。
            } else {
                // console.log(payload)

                // ダウンロード開始
                if (window.navigator.msSaveBlob) {//ie用なので
                    window.navigator.msSaveBlob(d, moment().format("YYYYMMDDHHmmss") + '.pdf');
                    checkOwnerDownload({ value: 1 })
                } else {

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    // ダウンロードされるファイル名
                    // a.download = action.payload.filename.replace("´", "");
                    a.download = moment().format("YYYYMMDDHHmmss") + '.pdf';
                    a.href = URL.createObjectURL(d);

                    checkOwnerDownload({ value: URL.createObjectURL(d) })
                    a.click();
                    checkOwnerDownload({ value: 2 })
                    document.body.removeChild(a);
                }


            }
            //

        } else {
            checkOwnerDownload({ value: 3 })
            alert("ダウンロードできませんでした。")
        }

    }
    const handlePdfOpen = (id) => {
        window.open(`/PdfView/1/` + id+`/1`, '_blank', 'noopener')
    }
    //ダウンロードチェック
    const checkOwnerDownload = async (values) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v1/owner/remittances/checkdownload/`, { method: 'post', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = f
        // console.log("ddd", d)

    }

    useEffect(() => {
    
        getOwnerRemittanceListData()//送金書一覧

    }, [])

    const onClickMore = (index) => {
        navigate('/soukinMeisai')
      
    }
    
    return (
    <Box sx={{p: 1}}>
        <Box sx={{fontSize: '20px'}}>
            <ArticleIcon sx={{ fontSize: 35, color: '#003366', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>送金明細</Box>
        </Box>
        {data_loading ? <List sx={{ paddingTop: 0 }}>
            {owner_remittance_list_disp.length > 0 ? owner_remittance_list_disp.map(function (value, index) {
            return <>
            <ListItem sx={{ display: 'block', p: 1 }}>
                <ListItemText
                    sx={{ display: 'flex' }}
                    primary={moment(value.so_ymd).format("YYYY年M月D日")}
                    secondary={value.bk_name}
                    primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: 'medium',
                        width: 170,
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        width: '-webkit-fill-available',
                        fontWeight: 'bold',
                        fontSize: 15,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'Noto Sans JP',
                        color: 'black',
                        ml: 1
                    }}
                />
                <Box sx={{textAlign: 'right'}}>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" onClick={()=>downloadOwnerRemittance(value.id)} disableElevation size="small" startIcon={<DownloadIcon />} color='btn_color' sx={{ml: 4, mr: 1, fontFamily: 'Noto Sans JP'}}>ダウンロード</Button>
                        <Button variant="contained" onClick={()=>handlePdfOpen(value.id)} disableElevation size="small" startIcon={<MonitorIcon />} color='btn_color' sx={{fontFamily: 'Noto Sans JP'}}>表示</Button>
                    </ThemeProvider>
                </Box>
            </ListItem>
            <Divider />
            </>
            }):<>
                <ListItem className='list_style'>
                    <ListItemText
                        primary="送金明細はありません。"
                        secondary=""
                        primaryTypographyProps={{
                            fontWeight: 'medium',
                            marginRight: 10,
                            display: 'inline-block',
                            fontFamily: 'Noto Sans JP'
                        }}
                        secondaryTypographyProps={{
                            fontWeight: 'medium',
                            display: 'inline-block',
                            fontFamily: 'Noto Sans JP'
                        }}
                    />
                </ListItem>
            </>
            }
        </List>:<Loading />}
        {owner_remittance_list.length > 0 && owner_remittance_list.length > owner_remittance_1page_count ? <Stack spacing={2} sx={{alignItems: 'center'}}>
            <ThemeProvider theme={theme}>
                <Pagination count={Math.ceil(owner_remittance_list.length/owner_remittance_1page_count)} page={owner_remittance_now_page} color="page_color" onChange={(e,value)=>handleChangeOwnerRemittancePage(value)} />
            </ThemeProvider>
        </Stack>:""}
        <div style={{textAlign: 'center', marginTop:"15px"}}>
            {owner_remittance_list.length > 0 ?
            // {owner_remittance_list > owner_remittance_list_disp ?
                <ThemeProvider theme={theme}>
                <Button sx={{width: 250, fontFamily: 'Noto Sans JP'}} variant="contained" disableElevation color='list_btn_color' onClick={onClickMore}>送金明細へ</Button>
                {/* <Button sx={{width: 250}} variant="contained" disableElevation color='list_btn_color' onClick={onClickMore}>もっと表示する</Button> */}
            </ThemeProvider>:""}
        </div>
    </Box>
    )
};
  
export default RemittanceMobile;