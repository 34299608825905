import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import ApartmentIcon from '@mui/icons-material/Apartment';


const drawerWidth = 190;

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [select_menu, setSelectMenu] = useState(0);//

  const [menu_list, setMenuList] = useState([
    {id:0, label:'概　　要', url:"gaiyou"},
    {id:1, label:'送金明細', url:"soukinMeisai"}, 
    {id:2, label:'賃貸借契約', url:"denshiKeiyaku"}, 
    {id:3, label:'清掃報告', url:"seisouHoukoku"}, 
    {id:4, label:'BM 報 告', url:"pmHoukoku"}, 
    {id:5, label:'入居者ﾘｸｴｽﾄ', url:"nyukyoshaRequest"}, 
    {id:6, label:'所有物件', url:"shoyuBukken"}])

  const onClickMenu = (index) => {
    setSelectMenu(index)

      navigate('/'+menu_list.filter(a=>a.id == index)[0].url)
    
  }

  return <Drawer
    variant="permanent"
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#003366' },
    }}
  >
    <Toolbar />
    {/* {location.pathname} */}
    <Box sx={{ overflow: 'auto' }}>
      <List
        sx={{
          '& .MuiListItemButton-root:hover': {
            bgcolor: '#316689',
          },
        }}
      >
        {menu_list.map((text, index) => (
          <ListItem key={text.label} style={{padding: 0, color: 'white'}}>
            <ListItemButton
              sx={{
                height: window.innerHeight/9,
              }}
              onClick={() => onClickMenu(index)}
            >
              {index === 0 ? <HomeIcon />
                : index === 1 ? <ArticleIcon />
                  : index === 2 ? <ContactPageIcon />
                    : index === 3 ? <AutoAwesomeIcon />
                      : index === 4 ? <EngineeringIcon />
                        : index === 5 ? <SmsFailedIcon />
                          : index === 6 ? <ApartmentIcon /> : ""}
              <ListItemText primary={text.label} sx={{ textAlign: "center" }} primaryTypographyProps={{ letterSpacing: '0.2em', fontFamily: 'Noto Sans JP' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  </Drawer>
}


export default SideMenu;