import React, {useState, useContext, useEffect} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Document, Page, pdfjs } from 'react-pdf';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import Layout from '../Layouts/header';
import LayoutMobile from '../Layouts/layoutMobile';
import Notice from '../Components/notice';
import NoticeMobile from '../Components/noticeMobile';
import Remittance from '../Components/remittance';
import RemittanceMobile from '../Components/remittanceMobile';
import OwnedProperty from '../Components/ownedProperty';
import OwnedPropertyMobile from '../Components/ownedPropertyMobile';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RemoveCircleIcon from '@mui/icons-material/ZoomOut';
import AddCircleIcon from '@mui/icons-material/ZoomIn';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useParams,} from 'react-router-dom';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import '../styles/layout.css';
import '../styles/top.css';
import { PageHook } from '../PageHook';

const theme = createTheme({
  palette: {
    btn_page_color: {
      main: '#003366',
      contrastText: 'white',
    },
  },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const zoomlist=[
  {value:75, label:"75%"},
  {value:100, label:"100%"},
  {value:125, label:"125%"},
  {value:150, label:"150%"},
  {value:175, label:"175%"},
  {value:200, label:"200%"},
  {value:225, label:"225%"},
]

const PdfView = () => {    
    /**********必須**********/
  PageHook();
    
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
    const gamen_height = window.outerHeight - 56;

    const { id } = useParams();
    const { type } = useParams();
    const { date } = useParams();

    const { instance, inProgress, accounts } = useMsal();

    const [file_url, set_file_url] = useState("");
    const [file_base64, set_file_base64] = useState(null);
    const [file_name, set_file_name] = useState("");
    const [page, set_page] = useState(1);
    const [numPages, set_numPages] = useState(1);

    const [loading, setLoading] = useState(0);

    const [zoom, setZoom] = useState(125);
    

    const getPdf = async() => {

      var token = await GetAccessToken({ instance, inProgress, accounts }); 

      let url_tmp = ""
      if(type == 1){//送金明細
        url_tmp = url + `/v2/owner/remittances/download/`+id
      }else if(type == 2){//入居者リクエスト 定期報告書 テンプレあり
        url_tmp = url + `/v2/owner/remittances/request/`+id+`/20230901`
      }else if(type == 3){//契約書 テンプレあり
        // url_tmp = `https://doc.homestation.jp/api/IPcontract/` + id + `/A3`
        url_tmp = url + `/v2/t/owner/room/contractdownloadr/1/`+id
        // url_tmp = `https://doc.homestation.jp/api/contract/` + id + `/A3/?send=1`
      }else if(type == 4){//重説 テンプレあり
        // url_tmp = `https://doc.homestation.jp/api/IPdisclosureStatement/` + id + `/A3`
        url_tmp = url + `/v2/t/owner/room/contractdownloadr/2/`+id
      }else if(type == 5){//BM報告書
        url_tmp = url + `/v2/owner/remittances/junkaihoukoku/`+id
      }else if(type == 6){//P契約書
        url_tmp = url + `/v2/t/owner/room/contractdownloadp/`+id
      }else if(type == 7){//電子契約 使ってない。
        url_tmp = url + `/v2/t/owner/room/contractdownloadelec/`+id
      }else if(type == 8){//清算書
        url_tmp = url + `/v2/owner/remittances/downloadseisan/`+id
      }else if(type == 9){//清掃報告書 テンプレあり
        url_tmp = url + `/v2/t/owner/room/cleaningdownload/`+id+`/`+date
      }
      // console.log("toooooooo", token)
      const f = await fetch(url_tmp, {
          method: 'get',
          // body: JSON.stringify(search),
          headers: {'Authorization': 'Bearer ' + token, 'Content-type': 'application/pdf; charset=UTF-8'},

      }).then(response => response.blob())

      .then((payload) => {
// alert(payload.size)
          setLoading(1)
          if(payload.size == 0){
            
          }else{
            const reader = new FileReader()
            reader.readAsDataURL(payload)
  
            
            reader.onload = () => {
                set_file_url(payload)
   
                set_file_base64(reader.result)
                set_file_name(reader.name)
  
                
  
            }
          }
          
      })
    }

    useEffect(() => {

      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    
      getPdf()//送金書取得
      // alert()

    }, [])

    const handleZoom = async(type) => {

      var index = zoomlist.findIndex(a=>a.value == zoom)
  
        setZoom(zoomlist[index + type].value)
  
    }

    return (

      <Box>
      {/* {isDesktop ?<Layout />:<LayoutMobile />} */}
        
      {loading == 1 ? <>
      {isDesktop ? <Stack spacing={2}  alignItems="center" style={{marginTop:10}}>

        <div style={{display:"flex"}}>
          <ThemeProvider theme={theme}>
              <Pagination count={numPages} page={page} siblingCount={0} color="btn_page_color" onChange={(e,value)=>set_page(value)} />
          </ThemeProvider>

          <div>

            <IconButton disabled={!(zoom > 75)} onClick={()=>handleZoom(-1)} style={{marginLeft:15}}>
              <RemoveCircleIcon />
            </IconButton>
            <FormControl variant="outlined" style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-outlined-label">ズーム</InputLabel>
            </FormControl>
            <IconButton disabled={!(zoom < 225)} onClick={()=>handleZoom(1)}>
              <AddCircleIcon />
            </IconButton>

          </div>

        </div>
        
      </Stack>:""}
      {/* <IconButton aria-label="left"
          disabled={page <= 1}
          onClick={() => set_page(page - 1)}>
          <ChevronLeftIcon />
      </IconButton>
      <span style={{ marginTop: 10 }}> {page || 1} / {numPages || '-'}</span>
      <IconButton aria-label="left"
          disabled={page >= numPages || !numPages}
          onClick={() => set_page(page + 1)}>
          <ChevronRightIcon />
      </IconButton> */}



      <div style={{ height: isDesktop ? window.innerHeight-110 : window.innerHeight-55, textAlign: "center"}}>

        <div style={{overflow: "auto", background:"#696969", height:isDesktop ? window.innerHeight-110 : window.innerHeight-55}} >
          {loading == 1 ? <div className="component">
          {
              file_url && file_base64 ? <Document
              file={file_base64}
              onLoadSuccess={a => set_numPages(a.numPages)}
            >
              <Page
              renderTextLayer={false} renderAnnotationLayer={false}
                rotate={0}
                scale={zoom / 100}
                // rotate={this.props.rotate}
                pageNumber={page}
                style={{ border: 'solid 2px black',  }}
              />
            </Document> : loading == 1 ? "表示できませんでした。":""
          }
          </div>:""}
        </div>
      </div>


      <Stack spacing={2}  alignItems="center" style={{marginTop:10}}>

        <div style={{display:"flex"}}>
          <ThemeProvider theme={theme}>
              <Pagination count={numPages} page={page} siblingCount={0} color="btn_page_color" onChange={(e,value)=>set_page(value)} />
          </ThemeProvider>

          
          {isDesktop ? 
          <div>

            <IconButton disabled={!(zoom > 75)} onClick={()=>handleZoom(-1)} style={{marginLeft:15}}>
              <RemoveCircleIcon />
            </IconButton>
            <FormControl variant="outlined" style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-outlined-label">ズーム</InputLabel>
            </FormControl>
            <IconButton disabled={!(zoom < 225)} onClick={()=>handleZoom(1)}>
              <AddCircleIcon />
            </IconButton>

          </div>:""}

        </div>
        
      </Stack>

      {/* <IconButton aria-label="left"
          disabled={page <= 1}
          onClick={() => set_page(page - 1)}>
          <ChevronLeftIcon />
      </IconButton>
      <span style={{ marginTop: 10 }}> {page || 1} / {numPages || '-'}</span>
      <IconButton aria-label="left"
          disabled={page >= numPages || !numPages}
          onClick={() => set_page(page + 1)}>
          <ChevronRightIcon />
      </IconButton> */}
      
      
      </>:""}

    </Box>
    )

}

export default PdfView;