import React, {useState, useContext, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import MonitorIcon from '@mui/icons-material/Monitor';
import EngineeringIcon from '@mui/icons-material/Engineering';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import { GetAccessToken } from '../App'
import { useMsal } from '@azure/msal-react';
import moment from 'moment';
import Loading from "../Layouts/loading";
import { BlacklistedEQParams } from 'msal/lib-commonjs/utils/Constants';


const themeFont = createTheme({
    typography: {
        fontSize: 11
    }
})

const theme = createTheme({
    palette: {
      btn_color: {
        main: '#003366',
        contrastText: 'white',
      },
      list_btn_color: {
        main: 'black',
        contrastText: 'white',
      },
      btn_page_color: {
        main: '#003366',
        contrastText: 'white',
      },
    },
});

// const url = `http://localhost:5000`
const url = process.env.REACT_APP_API_BASE_URL

const PMReportDetailMobile = () => {

    const { instance, inProgress, accounts } = useMsal();
    const [data_loading, setDataLoading] = useState(0);//

    const [pm_report_list, setPmReportList] = useState([]);//リスト
    const [pm_report_list_disp, setPmReportListDisp] = useState([]);//リスト表示用
    const [pm_report_1page_count, setPmReport1PageCount] = useState(10);//リスト1ページに追加する件数
    const [pm_report_now_page, setPmReportNowPage] = useState(1);//リスト現在のページ
    const [pm_report_form, setPmReportFrom] = useState({ start_year: moment().format("YYYY"), start_month: moment().format("M"), end_year: moment().format("YYYY"), end_month: moment().format("M") })
    const [year_list, setYearList] = useState([]);//

    //送金書一覧
    const getPmReportListData = async (owner_remittance_form) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v1/owner/remittances/teiki`, { method: 'post', body: JSON.stringify(owner_remittance_form), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.json()

        d.sort(function (a, b) {
            if (a.so_ymd > b.so_ymd) {
                return -1;
            } else {
                return 1;
            }
        });

        setPmReportList(d)
        changePmReportListDisp(d, 1)
        setDataLoading(1)
    }
    //送金　今のページのリストをセットする。
    const changePmReportListDisp = async (list, count) => {
        let data = []
        for(let i = pm_report_1page_count * (count-1); i < list.length; i++){
            data.push(list[i])
            if(data.length == pm_report_1page_count){
                break
            }
        }
        setPmReportListDisp(data)
    }
    //送金 ページ変更時
    const handleChangePmReportPage = async (value) => {
        setPmReportNowPage(value)
        changePmReportListDisp(pm_report_list, value)// 今のページのリストをセットする。
    }
    //ダウンロード
    const downloadPmReport = async (id) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v2/owner/remittances/junkaihoukoku/`+id, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = await f.blob()

        checkOwnerDownload({ value: d.size })


        if (d.size) {
            if (d.size === 12) {
                // console.log(payload)
                // authentication.signOut();///////////////////////////////何かに置き換える。
            } else {
                // console.log(payload)

                // ダウンロード開始
                if (window.navigator.msSaveBlob) {//ie用なので
                    window.navigator.msSaveBlob(d, moment().format("YYYYMMDDHHmmss") + '.pdf');
                    checkOwnerDownload({ value: 1 })
                } else {

                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    // ダウンロードされるファイル名
                    // a.download = action.payload.filename.replace("´", "");
                    a.download = moment().format("YYYYMMDDHHmmss") + '.pdf';
                    a.href = URL.createObjectURL(d);

                    checkOwnerDownload({ value: URL.createObjectURL(d) })
                    a.click();
                    // checkOwnerDownload({ value: 2 })
                    document.body.removeChild(a);
                }


            }
            //

        } else {
            checkOwnerDownload({ value: 3 })
            alert("ダウンロードできませんでした。")
        }

    }

    //ダウンロードチェック
    const checkOwnerDownload = async (values) => {

        var token = await GetAccessToken({ instance, inProgress, accounts }); 
        
        const f = await fetch(url + `/v1/owner/remittances/checkdownload/`, { method: 'post', body: JSON.stringify(values), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const d = f
        // console.log("ddd", d)

    }

    useEffect(() => {
    
        getPmReportListData(pm_report_form)//送金書一覧
        getYearList()//年リスト


    }, [])

    const getYearList = () => {
        
        let data = []
        for(let i=2018; i<=parseInt(moment().format("YYYY"))+1; i++){
            data.push(i)
        }
        setYearList(data)

    }
    
    const handleChange = (e) => {
        
        let newDetail = {...pm_report_form}
        newDetail[e.target.name] = e.target.value
        
        setPmReportFrom(newDetail)

        // getOwnerRemittanceListData(newDetail)

    }

    const handleClickSearch = () => {
        
        getPmReportListData(pm_report_form)
        setPmReportNowPage(1)

    }

    const handlePdfOpen = (id) => {
        window.open(`/PdfView/5/` + id+`/1`, '_blank', 'noopener')
    }

    return(
    <Box>
        <Box sx={{ fontSize: 20 }}>
            <Box sx={{display: 'flex', mt: 1, mb: 2}}>
                <EngineeringIcon sx={{ fontSize: 35, color: '#003366', verticalAlign: 'middle', ml: 1 }} />
                <Container component='span' sx={{ fontWeight: 'bold', verticalAlign: 'middle', mt: 0.4 }}>BM報告</Container>
            </Box>
            {/* <Box sx={{display: 'flex'}}> */}
            <Box sx={{display: 'none'}}>
                <Container sx={{ mb: 1, p: 0, display: 'flex', width: 'auto' }}>
                    <FormControl sx={{ width: 75, mr: 0.1 }} size="small">
                        <InputLabel id="start_year_label" sx={{fontSize: 12}}>年</InputLabel>
                        <ThemeProvider theme={themeFont}>
                            <Select
                                labelId="start_year_select_label"
                                id="start_year_select"
                                name="start_year"
                                value={pm_report_form.start_year}
                                label="年"
                                onChange={handleChange}
                                sx={{fontFamily: 'Noto Sans JP'}}
                            >
                                {year_list.length > 0 ? year_list.map(function (value, index) {
                                    return <MenuItem value={value}>{value}年</MenuItem>
                                }):null}
                            </Select>
                        </ThemeProvider>
                    </FormControl>
                    <FormControl sx={{ width: 65 }} size="small">
                        <InputLabel id="start_month_label" sx={{fontSize: 12}}>月</InputLabel>
                        <ThemeProvider theme={themeFont}>
                            <Select
                                labelId="start_month_select_label"
                                id="start_month_select"
                                name="start_month"
                                value={pm_report_form.start_month}
                                label="月"
                                onChange={handleChange}
                                sx={{fontFamily: 'Noto Sans JP'}}
                            >
                                <MenuItem value={1}>1月</MenuItem>
                                <MenuItem value={2}>2月</MenuItem>
                                <MenuItem value={3}>3月</MenuItem>
                                <MenuItem value={4}>4月</MenuItem>
                                <MenuItem value={5}>5月</MenuItem>
                                <MenuItem value={6}>6月</MenuItem>
                                <MenuItem value={7}>7月</MenuItem>
                                <MenuItem value={8}>8月</MenuItem>
                                <MenuItem value={9}>9月</MenuItem>
                                <MenuItem value={10}>10月</MenuItem>
                                <MenuItem value={11}>11月</MenuItem>
                                <MenuItem value={12}>12月</MenuItem>
                            </Select>
                        </ThemeProvider>
                    </FormControl>
                </Container>
                -
                <Container sx={{ display: 'flex', p: 0, width: 'auto' }}>
                    <FormControl sx={{ width: 75, mr: 0.1 }} size="small">
                        <InputLabel id="end_year_label" sx={{fontSize: 12}}>年</InputLabel>
                        <ThemeProvider theme={themeFont}>
                            <Select
                                labelId="end_year_select_label"
                                id="end_year_select"
                                name="end_year"
                                value={pm_report_form.end_year}
                                label="年"
                                onChange={handleChange}
                                sx={{fontFamily: 'Noto Sans JP'}}
                            >
                                {year_list.length > 0 ? year_list.map(function (value, index) {
                                    return <MenuItem value={value}>{value}年</MenuItem>
                                }):null}
                            </Select>
                        </ThemeProvider>
                    </FormControl>
                    <FormControl sx={{ minWidth: 65 }} size="small">
                        <InputLabel id="end_month_label" sx={{fontSize: 12}}>月</InputLabel>
                        <ThemeProvider theme={themeFont}>
                            <Select
                                labelId="end_month_select_label"
                                id="end_month_select"
                                name="end_month"
                                value={pm_report_form.end_month}
                                label="月"
                                onChange={handleChange}
                                sx={{fontFamily: 'Noto Sans JP'}}
                            >
                                <MenuItem value={1}>1月</MenuItem>
                                <MenuItem value={2}>2月</MenuItem>
                                <MenuItem value={3}>3月</MenuItem>
                                <MenuItem value={4}>4月</MenuItem>
                                <MenuItem value={5}>5月</MenuItem>
                                <MenuItem value={6}>6月</MenuItem>
                                <MenuItem value={7}>7月</MenuItem>
                                <MenuItem value={8}>8月</MenuItem>
                                <MenuItem value={9}>9月</MenuItem>
                                <MenuItem value={10}>10月</MenuItem>
                                <MenuItem value={11}>11月</MenuItem>
                                <MenuItem value={12}>12月</MenuItem>
                            </Select>
                        </ThemeProvider>
                    </FormControl>
                </Container>
                <ThemeProvider theme={theme}>
                    <Button variant="contained" disableElevation color='btn_page_color' sx={{ fontSize:12, verticalAlign: 'middle', height: 'auto', ml: 0, mb: 1 }}>検 索</Button>
                </ThemeProvider>
            </Box>
        </Box>
        {pm_report_list_disp.length > 0 ? <><Container sx={{ display: 'flex' }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', width: 170, fontFamily: 'Noto Sans JP' }}>
                報告日
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', width: '-webkit-fill-available', fontFamily: 'Noto Sans JP', ml: 1 }}>
                建物名
            </Typography>
        </Container>
        <Divider /></>:""}
        {data_loading ? <List sx={{mb: 4, pt: 0}}>
            {pm_report_list_disp.length > 0 ? pm_report_list_disp.map(function (value, index) {
            return <>
            <ListItem sx={{ display: 'block' }}>
                <ListItemText
                    sx={{ display: 'flex' }}
                    primary={moment(value.updated_at).format("YYYY年M月D日")}
                    secondary={value.name}
                    primaryTypographyProps={{
                        fontSize: 14,
                        fontWeight: 'medium',
                        width: 170,
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        width: '-webkit-fill-available',
                        fontWeight: 'bold',
                        fontSize: 15,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'Noto Sans JP',
                        ml: 1,
                        color: 'black'
                    }}
                />
                <Box sx={{textAlign: 'right'}}>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" onClick={()=>downloadPmReport(value.id)} disableElevation size="small" startIcon={<DownloadIcon />} color='btn_color' sx={{ml: 4, mr: 1, fontFamily: 'Noto Sans JP'}}>ダウンロード</Button>
                        <Button variant="contained" onClick={()=>handlePdfOpen(value.id)} disableElevation size="small" startIcon={<MonitorIcon />} color='btn_color' sx={{fontFamily: 'Noto Sans JP'}}>表示</Button>
                    </ThemeProvider>
                </Box>
            </ListItem>
            <Divider />
            </>
            }):
            <>
            <ListItem sx={{ display: 'block' }}>
                <ListItemText
                    sx={{ display: 'flex' }}
                    primary="BM報告はありません。"
                    secondary=""
                    primaryTypographyProps={{
                        fontWeight: 'medium',
                        width: 200,
                        fontFamily: 'Noto Sans JP'
                    }}
                    secondaryTypographyProps={{
                        fontWeight: 'medium',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'Noto Sans JP'
                    }}
                />

            </ListItem>
            </>
            }
        </List>:<Loading />}
        {pm_report_list.length > 0 && pm_report_list.length > pm_report_1page_count ? <Stack spacing={2} style={{alignItems: 'center'}}>
            <ThemeProvider theme={theme}>
                <Pagination count={Math.ceil(pm_report_list.length/pm_report_1page_count)} page={pm_report_now_page} color="btn_page_color" onChange={(e,value)=>handleChangePmReportPage(value)} />
            </ThemeProvider>
        </Stack>:""}
    </Box>
)};
  
export default PMReportDetailMobile;