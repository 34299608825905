import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Grid } from '@mui/material';
import Layout from '../Layouts/layout';
import LayoutMobile from '../Layouts/layoutMobile';
import RemittanceDetail from '../Components/remittanceDetail';
import RemittanceDetailPad from '../Components/remittanceDetailPad';
import RemittanceDetailMobile from '../Components/remittanceDetailMobile';
import { PageHook } from '../PageHook';


const SoukinMeisai = () => {
  /**********必須**********/
  PageHook();
  const isDesktop = useMediaQuery({ query: '(min-width: 980px)' })
  const isPad = useMediaQuery({ query: '(min-width: 768px)' })
  const gamen_height = window.outerHeight - 56;

  return (
    isDesktop ? 
    <Box sx={{ display: 'flex' }}>
      <Layout />
      <Grid container spacing={2} style={{backgroundColor: 'lightgray', marginLeft: 0, marginTop: 65}}>
        <Grid item xs={12} md={12} sx={{backgroundColor: 'white', outline: 'auto', outlineColor: 'lightgray'}} style={{padding: '30px', outline: 'none'}}>
          <RemittanceDetail />
        </Grid>
      </Grid>        
    </Box>
    : isPad ?
    <Box sx={{ display: 'flex' }}>
      <Layout />
      <Grid container spacing={2} style={{backgroundColor: 'lightgray', marginLeft: 0, marginTop: 65}}>
        <Grid item xs={12} md={12} sx={{backgroundColor: 'white', outline: 'auto', outlineColor: 'lightgray'}} style={{padding: '30px', outline: 'none'}}>
          <RemittanceDetailPad />
        </Grid>
      </Grid>        
    </Box>
    :
    <Box>
      <LayoutMobile />
      <Grid item xs={12} sx={{backgroundColor: 'white', outline: 'auto', outlineColor: 'lightgray', p: 1, height: gamen_height }}>
        <RemittanceDetailMobile />
      </Grid>
    </Box>
  );
}

export default SoukinMeisai;