import React, {createContext, useState, useContext} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Grid } from '@mui/material';
import Layout from '../Layouts/layout';
import LayoutMobile from '../Layouts/layoutMobile';
import Notice from '../Components/notice';
import NoticePad from '../Components/noticePad';
import NoticeMobile from '../Components/noticeMobile';
import Remittance from '../Components/remittance';
import RemittancePad from '../Components/remittancePad';
import RemittanceMobile from '../Components/remittanceMobile';
import OwnedProperty from '../Components/ownedProperty';
import OwnedPropertyPad from '../Components/ownedPropertyPad';
import OwnedPropertyMobile from '../Components/ownedPropertyMobile';
import '../styles/layout.css';
import '../styles/top.css';
import { useNavigate, useLocation } from 'react-router-dom';
import {UserContext,} from '../pages/index'
import { PageHook } from '../PageHook';
const Gaiyou = () => {
    /**********必須**********/
  PageHook();
    const isDesktop = useMediaQuery({ query: '(min-width: 980px)' })
    const isPad = useMediaQuery({ query: '(min-width: 768px)' })

    const navigate = useNavigate();
    // const user = useContext(UserContext)
    const { user_detail } = useContext(UserContext);

    React.useEffect(() => {
    
      if(user_detail.owner_none){
        navigate('/shokaiShounin')
      }
  
    }, []);  
    
    
    return (
      isDesktop && user_detail.owner_id ?
      <Box sx={{ display: 'flex'}}>
        <Layout />
        <Grid container spacing={2} className='top_container' style={{marginLeft: 0}}>
          <Grid item md={12} className='top_grid' style={{padding: '30px'}}>
            <Notice />
          </Grid>
          <Grid item md={12} className='top_grid' style={{padding: '30px'}}>
            <Remittance />
          </Grid>
          <Grid item md={12} className='top_grid' style={{padding: '30px'}}>
            <OwnedProperty />
          </Grid>
        </Grid>
      </Box>
      : isPad && user_detail.owner_id ?
      <Box sx={{ display: 'flex' }}>
        <Layout />
        <Grid container spacing={2} className='top_container' style={{marginLeft: 0}}>
          <Grid item md={12} className='top_grid' style={{padding: '30px'}}>
            <NoticePad />
          </Grid>
          <Grid item md={12} className='top_grid' style={{padding: '30px'}}>
            <RemittancePad />
          </Grid>
          <Grid item md={12} className='top_grid' style={{padding: '30px'}}>
            <OwnedPropertyPad />
          </Grid>
        </Grid>
      </Box>
      :
      user_detail.owner_id ? <Box>
        <LayoutMobile />
        <Grid container className='top_container' sx={{ ml: 0 }}>
          <Grid item xs={12} className='top_grid' sx={{ m: 1, mb: 5 }}>
            <NoticeMobile />
          </Grid>
          <Grid item xs={12} className='top_grid' sx={{ ml: 1, mr: 1, mb: 5 }}>
            <RemittanceMobile />
          </Grid>
          <Grid item xs={12} className='top_grid' sx={{ ml: 1, mr: 1, mb: 1 }}>
            <OwnedPropertyMobile />
          </Grid>
        </Grid>
      </Box>:""
    );
}

export default Gaiyou;