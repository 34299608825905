import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box } from '@mui/material';
import Layout from '../Layouts/layout';
import LayoutMobile from '../Layouts/layoutMobile';
import OwnedPropertyDetail from '../Components/ownedPropertyDetail';
import OwnedPropertyDetailPad from '../Components/ownedPropertyDetailPad';
import OwnedPropertyDetailMobile from '../Components/ownedPropertyDetailMobile';
import { PageHook } from '../PageHook';


const ShoyuBukken = () => {
  /**********必須**********/
  PageHook();
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })
  const isPad = useMediaQuery({ query: '(min-width: 768px)' })

  return (
    isDesktop ?
      <Box sx={{ml: 24, mt: 10}}>
        <Layout />
        <OwnedPropertyDetail />
      </Box> 
    : isPad ?
      <Box sx={{ml: 24, mt: 10}}>
        <Layout />
        <OwnedPropertyDetailPad />
      </Box> 
    :
      <Box>
        <LayoutMobile />
        <OwnedPropertyDetailMobile />
      </Box>
  );
}

export default ShoyuBukken;