import React, { useState, useRef, useEffect } from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import { Container } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useNavigate  } from 'react-router-dom';
import '../styles/emailAddress.css';


const theme = createTheme({
    palette: {
      btn_color: {
        main: 'black',
        contrastText: 'white',
      },
    },
});


const EmailAddCertification = () => {
    const navigate = useNavigate();

    const [code, setCode] = useState(["", "", "", "", "", ""])
    const [inputIndex, setIndex] = useState(0)
  
    const inputRef = [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ]
  
    const bsFunction = (event) => {
      if (event.key === "Backspace" && inputIndex > 0) {
        inputRef[inputIndex - 1].current.focus()
        setIndex(inputIndex - 1)
      }
    }
  
    useEffect(() => {
      document.addEventListener("keydown", bsFunction, false)
      return () => {
        document.removeEventListener("keydown", bsFunction, false)
      }
    }, [bsFunction])
  
    return (
    <Box>
        <Container sx={{fontSize: 20, textAlign: 'left', ml: 4, mb: 5}}>
            <EmailIcon sx={{ fontSize: 35, color: '#316689', mr: 1, verticalAlign: 'middle'}} /><Box component='span' sx={{fontWeight: 'bold', verticalAlign: 'middle'}}>メールアドレス変更</Box>
        </Container>
        <Container sx={{mt: 20, textAlign: '-webkit-center'}}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', letterSpacing: 2, width: 500, mb: 1 }}>
                認証コードをご入力ください。
            </Typography>

            {[...Array(6)].map((_, i) => (
                <input className="certification_style"
                    maxLength={1}
                    key={i}
                    autoFocus={i === 0}
                    value={code[i]}
                    type="tel"
                    ref={inputRef[i]}
                    onChange={(e) => {
                    const codeArray = [
                        i !== 0 ? code[0] : e.target.value,
                        i !== 1 ? code[1] : e.target.value,
                        i !== 2 ? code[2] : e.target.value,
                        i !== 3 ? code[3] : e.target.value,
                        i !== 4 ? code[4] : e.target.value,
                        i !== 5 ? code[5] : e.target.value,
                    ]
                    setCode([...codeArray])
                    if (e.target.value !== "") {
                        i < 5 && inputRef[i + 1].current.focus()
                        setIndex(inputIndex + 1)
                    }
                }}
                />
            ))}
        </Container>
        
        <Container sx={{mt: 7, textAlign: 'center'}}>
            <ThemeProvider theme={theme}>
                <Button onClick={() => navigate('/emailaddressKanryo')} variant="contained" disableElevation color='btn_color' sx={{pl: 5, pr: 5}}>メールアドレスを認証する</Button>
            </ThemeProvider>
        </Container>
    </Box>
    )
};
  
export default EmailAddCertification;